import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "components/ui/Avatar";
import BuildingInterior from "components/ui/BuildingInterior";
import CharacterInfo from "components/ui/CharacterInfo";
import Fight from "components/ui/Fight";
import ShipInterior from "components/ui/ShipInterior";
import World from "components/world/World";
import { keyboardCommands } from "redux/actions";
import PlanetTravel from "./PlanetTravel";
import { useEffect, useRef } from "react";
import LinkSectorsOverlay from "components/ui/LinkSectorsOverlay";
import { getCharacter } from "redux/selectors";

const MapWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
`;

const KEYBOARD_KEYS = "left,right,a,d,esc";

export default function GameMap() {
  const dispatch = useDispatch();
  const animationFrameId = useRef<number>();
  const lastUpdateTime = useRef<number>(0);
  const currentDirection = useRef<string>("");
  const {
    data: { ui },
  } = useSelector(getCharacter);

  const updateMovement = (currentTime: number) => {
    const deltaTime = (currentTime - lastUpdateTime.current) / 1000;
    lastUpdateTime.current = currentTime;

    dispatch(
      keyboardCommands({
        key: currentDirection.current,
        isKeyUp: false,
        delta: deltaTime,
      })
    );

    animationFrameId.current = requestAnimationFrame((time) => {
      updateMovement(time);
    });
  };

  // Key down handler
  useHotkeys(KEYBOARD_KEYS, (event, handler) => {
    event.preventDefault();
    currentDirection.current = handler.shortcut;

    if (!animationFrameId.current) {
      lastUpdateTime.current = performance.now();
      animationFrameId.current = requestAnimationFrame((time) => {
        updateMovement(time);
      });
    }
  });

  // Key up handler
  useHotkeys(
    KEYBOARD_KEYS,
    (event, handler) => {
      event.preventDefault();

      // Send the key up immediately
      dispatch(
        keyboardCommands({
          key: handler.shortcut,
          isKeyUp: true,
          delta: 0,
        })
      );

      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        animationFrameId.current = undefined;
      }
    },
    { keyup: true }
  );

  // Cleanup
  useEffect(() => {
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <MapWrapper>
      <World>
        <Avatar />
      </World>
      <PlanetTravel />
      <ShipInterior />
      <BuildingInterior />
      <CharacterInfo />
      <Fight />
      {!!ui.isLinkSectorsOverlayOpen && <LinkSectorsOverlay />}
    </MapWrapper>
  );
}
