import parse from "html-react-parser";
import styled, { css } from "styled-components";
import { Fighter, SkillModel } from "types";

import energyImage from "assets/stat-energy.svg";
import { getDerivedStatData } from "libs/stats";
import { COLORS } from "utils/constants";
import Tooltip, {
  RechargeIcon,
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipRecharge,
} from "./Tooltip";
import { getHitChance } from "libs/fight";
import { formatPercentage } from "utils/formatters";

interface SkillProps {
  skill: SkillModel;
  attacker: Fighter;
  defender?: Fighter;
  isSmall?: boolean;
  isShowValues?: boolean;
  isRecharging?: boolean;
  rechargeTurns?: number;
  rechargePercentage?: number;
  onClick?: () => void;
}

interface SkillWrapperProps {
  isSmall: boolean;
}

interface SkillRechargeValueProps {
  isSmall: boolean;
}

interface SkillRechargeProps {
  percentage: number;
}

interface SkillImageProps {
  isRecharging: boolean;
}

const smallMixin = css`
  height: 30px;
  width: 30px;
`;

const SkillWrapper = styled.div<SkillWrapperProps>`
  border-radius: 4px;
  overflow: hidden;
  height: 44px;
  width: 44px;
  cursor: pointer;
  position: relative;
  user-select: none;

  ${(props) => props.isSmall && smallMixin}

  &:hover {
    filter: saturate(150%);
  }
`;

const SkillRechargeValue = styled.div<SkillRechargeValueProps>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 2;
  color: white;

  ${(props) => props.isSmall && `font-size: 18px;`}
`;

const SkillRecharge = styled.div<SkillRechargeProps>`
  height: ${(props) => props.percentage}%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: black;
  opacity: 0.8;
  transition: all 200ms ease-out;
  z-index: 1;
`;

const SkillImage = styled.img<SkillImageProps>`
  width: 100%;
  height: 100%;
  ${(props) => props.isRecharging && `filter: saturate(0%) brightness(50%);`}
`;

const SkillValues = styled.div`
  strong {
    color: ${COLORS.ENERGY};
  }
`;

const TooltipHitChanceDesc = styled(TooltipDesc)`
  strong {
    color: ${COLORS.PRECISION};
  }
`;

const Skill = ({
  skill,
  attacker,
  defender,
  isSmall = false,
  isShowValues = false,
  isRecharging = false,
  rechargeTurns = 0,
  rechargePercentage = 0,
  onClick,
}: SkillProps) => {
  const boostInfo = getDerivedStatData("energyMultiplier");

  const hitChance = !!defender
    ? getHitChance(
        attacker.data.stats.attackAccuracy,
        defender?.data.stats.attackEvasion
      )
    : 0;

  return (
    <Tooltip
      content={
        <>
          <TooltipName>{skill.name}</TooltipName>
          <TooltipDesc>{skill.description}</TooltipDesc>
          {!!skill.values && isShowValues && (
            <TooltipDesc>
              <SkillValues>
                {parse(
                  skill.values({
                    attacker: attacker,
                    defender: defender,
                  })
                )}
              </SkillValues>
            </TooltipDesc>
          )}
          {!!defender && (
            <TooltipDesc>
              <TooltipHitChanceDesc>
                {skill.ignoreDodge || skill.isSupport
                  ? parse(`The chance of successfully landing this skill is always 
                  <strong>${formatPercentage(1)}</strong>.`)
                  : parse(`Based on the attacker's accuracy vs. the defender's evasion, the current chance of successfully landing this skill is
                  <strong>${formatPercentage(hitChance)}</strong>.`)}
              </TooltipHitChanceDesc>
            </TooltipDesc>
          )}
          <TooltipInfo>
            {!!skill.hasAntimatterBoost && isShowValues && (
              <TooltipRecharge>
                <RechargeIcon image={energyImage} />
                {`Antimatter Boost: ${boostInfo.formatter(
                  attacker.data.stats.energyMultiplier
                )}`}
              </TooltipRecharge>
            )}
            <TooltipRecharge>
              <RechargeIcon image={energyImage} />
              {`Turns to Recharge: ${skill.recharge}`}
            </TooltipRecharge>
          </TooltipInfo>
        </>
      }
    >
      <SkillWrapper onClick={onClick} isSmall={isSmall}>
        {!!isRecharging && (
          <SkillRechargeValue isSmall={isSmall}>
            {rechargeTurns}
          </SkillRechargeValue>
        )}
        <SkillRecharge percentage={rechargePercentage} />
        <SkillImage src={skill.image} isRecharging={isRecharging} />
      </SkillWrapper>
    </Tooltip>
  );
};

export default Skill;
