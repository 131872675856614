import {
  DataWrapper,
  Section,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Shop from "components/base/Shop";

interface ShipworksShopBuyProps {
  shop: string;
}

export default function ShipworksShopBuy({ shop }: ShipworksShopBuyProps) {
  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Buy Upgrades</SectionTitle>
        <SectionContentScroll>
          <Shop isUpgrades isBuy shopSlug={shop} />
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Buy Supplies</SectionTitle>
        <SectionContentScroll>
          <Shop isUpgrades={false} isBuy shopSlug={shop} />
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
