import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import enhancementsImage from "assets/enhancements.svg";
import experienceImage from "assets/exp.svg";
import BattleRating from "components/base/BattleRating";
import Dialogs from "components/base/Dialogs";
import ItemIcon from "components/base/ItemIcon";
import {
  DialogWrapper,
  FightOverlayActions,
  ItemImageWrapper,
  ItemInfo,
  ItemName,
  ItemWrapper,
  ListItem,
  OverlayStatement,
  OverlayBox,
  OverlayButton,
  OverlayList,
  OverlaySubtitle,
  OverlayTitle,
} from "components/base/Overlay";
import RatingProgress from "components/base/RatingProgress";
import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import useDialog from "components/hooks/useDialog";
import { getMobOverlayDialog } from "libs/fight";
import { getItemData, getSuppliesByType } from "libs/item";
import { getAreaData, getPlanetData } from "libs/stats";
import {
  employSupply,
  fightAgain,
  onwardFightLose,
  onwardFightWin,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { COLORS } from "utils/constants";

interface GainImageProps {
  image: string;
}

const OverlayHighlight = styled.div`
  text-transform: uppercase;
  color: #e3cb44;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

const CurrentRatingWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RatingWrapper = styled.div`
  display: inline-block;
`;

const ProgressWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  padding: 12px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #43fffa10;
  border: 1px #43fffa10 solid;
`;

const RewardsWrapper = styled.div`
  margin-bottom: 15px;
`;

const ExperienceImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.EXPERIENCE}50;
`;

const CreditsImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.CREDITS}50;
`;

const EnhancementsImageWrapper = styled(ItemImageWrapper)`
  background-color: ${COLORS.ENHANCEMENTS}50;
`;

const GainImage = styled.div<GainImageProps>`
  width: 100%;
  height: 100%;
  mask: url(${(props) => props.image}) no-repeat center;
  mask-size: 60%;
`;

const ExperienceImage = styled(GainImage)`
  background-color: ${COLORS.EXPERIENCE};
`;

const CreditsImage = styled(GainImage)`
  background-color: ${COLORS.CREDITS};
`;

const EnhancementsImage = styled(GainImage)`
  background-color: ${COLORS.ENHANCEMENTS};
`;

const QuickRepairWrapper = styled.div``;

const RepairSupplies = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ItemQuantity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000090;
  color: white;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: opacity 200ms ease-out;
`;

const Item = styled.div`
  width: 44px;
  height: 44px;
  cursor: pointer;
  position: relative;

  &:hover ${ItemQuantity} {
    opacity: 1;
  }
`;

export default function FightResults() {
  const {
    data: { level, inventory, location },
  } = useSelector(getCharacter);
  const { opponent, status, results } = useSelector(getFight);
  const dispatch = useDispatch();

  const repairSupplies = getSuppliesByType(inventory.supplies, "health");

  const activeDialog = getMobOverlayDialog(opponent.dialogs, status);

  const { currentStep, currentDialog, hasAction } = useDialog(
    activeDialog || [],
    true
  );

  const currentPlanetInfo = getPlanetData(location.planet);
  const repairAreaInfo = getAreaData(currentPlanetInfo.mainArea);

  return (
    <OverlayBox>
      {status === "winResults" && (
        <>
          <OverlayTitle>You defeated {opponent.name}!</OverlayTitle>
          {!!opponent.isTutorial && (
            <DialogWrapper>
              <Dialogs
                avatar={opponent.images.pilot}
                currentStep={currentStep}
                currentDialog={currentDialog}
                hasAction={hasAction}
              />
            </DialogWrapper>
          )}
          <CurrentRatingWrapper>
            <OverlaySubtitle>Battle Rating</OverlaySubtitle>
            <RatingWrapper>
              <BattleRating rating={results.battleRating} />
            </RatingWrapper>
            <ProgressWrapper>
              <RatingProgress isFinal />
            </ProgressWrapper>
          </CurrentRatingWrapper>
          {!!results.isLevelUp && (
            <OverlayHighlight>
              Level Up! You are now level {level}
            </OverlayHighlight>
          )}
          <RewardsWrapper>
            <OverlaySubtitle>Rewards</OverlaySubtitle>
            <OverlayList>
              {!!results.isLevelUp && (
                <ListItem>
                  <ItemWrapper>
                    <Tooltip
                      content={
                        <>
                          <TooltipName>Medallions</TooltipName>
                          <TooltipDesc>
                            You earn medallions when you level up, more at
                            higher levels.
                          </TooltipDesc>
                          <TooltipDesc>
                            You can use medallions to either strengthen your
                            starship's core attributes or learn new pilot
                            skills. You can do this at the nearest Shipworks or
                            Pilot Academy in any city.
                          </TooltipDesc>
                        </>
                      }
                    >
                      <EnhancementsImageWrapper>
                        <EnhancementsImage image={enhancementsImage} />
                      </EnhancementsImageWrapper>
                    </Tooltip>
                    <ItemName>Medallions</ItemName>
                  </ItemWrapper>
                  <ItemInfo>{results.statPoints}</ItemInfo>
                </ListItem>
              )}
              <ListItem>
                <ItemWrapper>
                  <Tooltip
                    content={
                      <>
                        <TooltipName>Experience</TooltipName>
                        <TooltipDesc>
                          Gain experience to level up, which allows you to
                          improve your starship's stats and learn more pilot
                          skills.
                        </TooltipDesc>
                      </>
                    }
                  >
                    <ExperienceImageWrapper>
                      <ExperienceImage image={experienceImage} />
                    </ExperienceImageWrapper>
                  </Tooltip>
                  <ItemName>Experience Gained</ItemName>
                </ItemWrapper>
                <ItemInfo>{results.experience}</ItemInfo>
              </ListItem>
              <ListItem>
                <ItemWrapper>
                  <Tooltip
                    content={
                      <>
                        <TooltipName>Credits</TooltipName>
                        <TooltipDesc>
                          Collect credits to buy upgrades to install onto your
                          starship and other useful supplies. You can do this at
                          the nearest Shipworks in any city.
                        </TooltipDesc>
                      </>
                    }
                  >
                    <CreditsImageWrapper>
                      <CreditsImage image={creditsImage} />
                    </CreditsImageWrapper>
                  </Tooltip>
                  <ItemName>Credits Received</ItemName>
                </ItemWrapper>
                <ItemInfo>{results.credits}</ItemInfo>
              </ListItem>
              {results.drops.map((drop) => (
                <ListItem key={drop.slug}>
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <ItemIcon slug={drop.slug} />
                    </ItemImageWrapper>
                    <ItemName>{getItemData(drop.slug).name}</ItemName>
                  </ItemWrapper>
                  <ItemInfo>{drop.quantity}x</ItemInfo>
                </ListItem>
              ))}
            </OverlayList>
          </RewardsWrapper>
          {repairSupplies.length > 0 && (
            <QuickRepairWrapper>
              <OverlaySubtitle>Quick Repair</OverlaySubtitle>
              <RepairSupplies>
                {repairSupplies.map((supply) => (
                  <Item
                    key={supply.slug}
                    onClick={() =>
                      dispatch(
                        employSupply({ slug: supply.slug, showMessage: true })
                      )
                    }
                  >
                    <ItemIcon slug={supply.slug} />
                    <ItemQuantity>x{supply.quantity}</ItemQuantity>
                  </Item>
                ))}
              </RepairSupplies>
            </QuickRepairWrapper>
          )}
          <FightOverlayActions>
            <OverlayButton
              $style="normal"
              $size="large"
              onClick={() => dispatch(onwardFightWin())}
            >
              Onward
            </OverlayButton>
            <OverlayButton
              $style="important"
              $size="large"
              onClick={() => dispatch(fightAgain())}
            >
              Battle Again
            </OverlayButton>
          </FightOverlayActions>
        </>
      )}
      {status === "loseResults" && (
        <>
          <OverlayTitle>You were defeated by {opponent.name}.</OverlayTitle>
          {!!opponent.isTutorial && (
            <DialogWrapper>
              <Dialogs
                avatar={opponent.images.pilot}
                currentStep={currentStep}
                currentDialog={currentDialog}
                hasAction={hasAction}
              />
            </DialogWrapper>
          )}
          <OverlayStatement>
            Your ship will be sent back to {repairAreaInfo.name} for full
            repairs at a higher fee.
          </OverlayStatement>
          <OverlaySubtitle>Outcome:</OverlaySubtitle>
          <OverlayList>
            <ListItem>
              <ItemWrapper>
                <Tooltip
                  content={
                    <>
                      <TooltipName>Credits</TooltipName>
                      <TooltipDesc>
                        Collect credits to buy upgrades to install onto your
                        starship and other useful supplies. You can do this at
                        the nearest Shipworks in any city.
                      </TooltipDesc>
                    </>
                  }
                >
                  <CreditsImageWrapper>
                    <CreditsImage image={creditsImage} />
                  </CreditsImageWrapper>
                </Tooltip>
                <ItemName>Full Repairs Cost</ItemName>
              </ItemWrapper>
              <ItemInfo>{results.credits}</ItemInfo>
            </ListItem>
          </OverlayList>
          <FightOverlayActions>
            <OverlayButton
              $style="normal"
              $size="large"
              onClick={() => dispatch(onwardFightLose())}
            >
              Onward
            </OverlayButton>
          </FightOverlayActions>
        </>
      )}
    </OverlayBox>
  );
}
