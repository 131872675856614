import React from "react";
import styled from "styled-components";

import { getBattleRatingData } from "libs/fight";
import { BattleRatings } from "types";
import { ratingAnimation } from "../styles/animations";
import Tooltip, { TooltipDesc, TooltipName } from "./Tooltip";

interface BattleRatingProps {
  rating: BattleRatings;
  iconOnly?: boolean;
  noTooltip?: boolean;
}

interface battleRatingImageProps {
  isGlowing: boolean;
  isSmall: boolean;
}

interface BattleRatingNameProps {
  color: string;
}

const BattleRatingWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  user-select: none;
`;

const BattleRatingImage = styled.img<battleRatingImageProps>`
  height: 60px;
  animation: ${ratingAnimation} 1.5s ease-in-out infinite;
  ${(props) => !props.isGlowing && "animation-play-state: paused;"}
  ${(props) => props.isSmall && "height: 24px;"}
`;

const BattleRatingName = styled.div<BattleRatingNameProps>`
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.color};
`;

export default function BattleRating({
  rating,
  iconOnly = false,
  noTooltip = false,
}: BattleRatingProps) {
  const battleRatingData = getBattleRatingData(rating);
  const isGlowing = rating === "platinum";

  return (
    <Tooltip
      isDisabled={noTooltip}
      content={
        <>
          <TooltipName>Battle Rating: {battleRatingData.name}</TooltipName>
          <TooltipDesc>{battleRatingData.description}</TooltipDesc>
        </>
      }
    >
      <BattleRatingWrapper>
        <BattleRatingImage
          isGlowing={isGlowing}
          isSmall={iconOnly}
          src={battleRatingData.image}
        />
        {!iconOnly && (
          <BattleRatingName color={battleRatingData.color}>
            {battleRatingData.name}
          </BattleRatingName>
        )}
      </BattleRatingWrapper>
    </Tooltip>
  );
}
