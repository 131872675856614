import { useDispatch, useSelector } from "react-redux";
import { hideLinkSectorsOverlay, travelToLinkSector } from "redux/actions";
import styled, { css } from "styled-components";

import {
  OverlayActions,
  OverlayBox,
  OverlayButton,
  OverlayContent,
  OverlaySection,
  OverlaySubtitle,
  OverlayTitle,
} from "components/base/Overlay";
import { getSectorsByPlanet } from "libs/character";
import { getAreaData, getPlanetData } from "libs/stats";
import { getCharacter } from "redux/selectors";
import { useImmer } from "use-immer";
import { useMemo } from "react";

interface LinkSectorsOverlayState {
  selectedLinkSector: string | null;
}

const LinkSectorsWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const LinkSectorsBackground = styled.div`
  background-color: #00000085;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const LinkSectorsOverlayBox = styled(OverlayBox)`
  min-width: 400px;
`;

const TravelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const SectorsList = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 30px;
`;

const linkSectorMixin = css`
  cursor: pointer;
`;

const currentSectorMixin = css`
  cursor: default;
`;

const Sector = styled.div<{
  $isLinkSector: boolean;
  $isCurrentSector: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  ${({ $isLinkSector }) => $isLinkSector && linkSectorMixin}
  ${({ $isCurrentSector }) => $isCurrentSector && currentSectorMixin}
`;

const linkSectorIconMixin = css`
  opacity: 1;
  width: 20px;
  height: 20px;
  border-width: 4px;
`;

const selectedSectorIconMixin = css`
  border-color: #43fffa;

  &::after {
    background-color: #43fffa;
  }
`;

const currentSectorIconMixin = css`
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  }
`;

const SectorIcon = styled.div<{
  $isSelected: boolean;
  $isLinkSector: boolean;
  $isCurrentSector: boolean;
}>`
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 0.5;

  ${({ $isLinkSector }) => $isLinkSector && linkSectorIconMixin}
  ${({ $isSelected }) => $isSelected && selectedSectorIconMixin}
  ${({ $isCurrentSector }) => $isCurrentSector && currentSectorIconMixin}
`;

const linkSectorNameMixin = css`
  opacity: 1;
  bottom: 100%;
  top: unset;
  margin-bottom: 5px;
  font-size: 14px;
`;

const selectedSectorNameMixin = css`
  color: #43fffa;
`;

const SectorName = styled.div<{
  $isLinkSector: boolean;
  $isSelected: boolean;
}>`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  white-space: nowrap;
  transform-origin: left top;
  text-align: center;
  opacity: 0.5;

  ${({ $isLinkSector }) => $isLinkSector && linkSectorNameMixin}
  ${({ $isSelected }) => $isSelected && selectedSectorNameMixin}
`;

const LinkLine = styled.div`
  width: 75px;
  border-bottom: 1px solid white;
  height: 0;
  opacity: 0.5;
`;

export default function LinkSectorsOverlay() {
  const dispatch = useDispatch();
  const {
    data: { location },
  } = useSelector(getCharacter);
  const { planet, area: currentSector } = location;
  const allSectors = getSectorsByPlanet(planet);
  const { name: planetName, linkSectors } = getPlanetData(planet);

  const [state, setState] = useImmer<LinkSectorsOverlayState>({
    selectedLinkSector: null,
  });

  const selectedSectorInfo = useMemo(() => {
    if (!state.selectedLinkSector) {
      return null;
    }
    return getAreaData(state.selectedLinkSector);
  }, [state.selectedLinkSector]);

  const handleLinkSectorChange = (linkSector: string) => {
    setState((draft: LinkSectorsOverlayState) => {
      draft.selectedLinkSector = linkSector;
    });
  };

  return (
    <LinkSectorsWrapper>
      <LinkSectorsBackground
        onClick={() => dispatch(hideLinkSectorsOverlay())}
      />
      <LinkSectorsOverlayBox>
        <>
          <OverlayTitle>Sector Link: {planetName}</OverlayTitle>
          <OverlayContent>
            <OverlaySection>
              <TravelWrapper>
                <OverlaySubtitle>
                  Select a linked sector for instant travel
                </OverlaySubtitle>
                <SectorsList>
                  {allSectors.map((sector, index) => {
                    const isCurrentSector = currentSector === sector;
                    const isLinkSector = linkSectors.includes(sector);
                    const isSelected = state.selectedLinkSector === sector;
                    const { name } = getAreaData(sector);
                    return (
                      <>
                        <Sector
                          key={sector}
                          $isLinkSector={isLinkSector}
                          $isCurrentSector={isCurrentSector}
                          onClick={() => {
                            if (isCurrentSector || !isLinkSector) {
                              return;
                            }
                            handleLinkSectorChange(sector);
                          }}
                        >
                          <SectorIcon
                            $isSelected={isSelected}
                            $isLinkSector={isLinkSector}
                            $isCurrentSector={isCurrentSector}
                          />
                          <SectorName
                            $isLinkSector={isLinkSector}
                            $isSelected={isSelected}
                          >
                            {name}
                          </SectorName>
                        </Sector>
                        {index < allSectors.length - 1 && <LinkLine />}
                      </>
                    );
                  })}
                </SectorsList>
                <OverlayActions>
                  <OverlayButton
                    $style="important"
                    $size="medium"
                    $disabled={!state.selectedLinkSector}
                    onClick={() => {
                      if (!!state.selectedLinkSector) {
                        dispatch(travelToLinkSector(state.selectedLinkSector));
                      }
                    }}
                  >
                    Travel to {selectedSectorInfo?.name || "Sector"}
                  </OverlayButton>
                </OverlayActions>
              </TravelWrapper>
            </OverlaySection>
          </OverlayContent>
        </>
      </LinkSectorsOverlayBox>
    </LinkSectorsWrapper>
  );
}
