import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import {
  planetAnimation,
  planetScreenAnimation,
} from "components/styles/animations";
import { getPlanetData } from "libs/stats";
import { useDispatch, useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import styled, { css } from "styled-components";
import { Planets } from "types";
import Button from "./Button";
import { ButtonCredits, ButtonCreditsIcon } from "./DataScreen";
import { buyPlanetPass, travelToPlanet } from "redux/actions";
import planetPassImage from "assets/icon-planet-pass.svg";

interface PlanetCardProps {
  planetSlug: Planets;
}

interface PlanetWrapperProps {
  $color: string;
}

interface ImageProps {
  $image: string;
  $color: string;
}

interface IconProps {
  $image: string;
}

const hoverMixin = css<PlanetWrapperProps>`
  background-color: ${(props) => props.$color}20;
  border-color: ${(props) => props.$color};
`;

const PlanetWrapper = styled.div<PlanetWrapperProps>`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;
  background-color: ${(props) => props.$color}20;
  color: ${(props) => props.$color};
  background-size: 4em 4em;
  background-position: center center;
  background-image: linear-gradient(to right, #aaaaaa30 1px, transparent 1px),
    linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${planetScreenAnimation} 1s linear infinite;
  border: 4px ${(props) => props.$color} solid;

  :hover {
    ${hoverMixin}
  }
`;

const PlanetImageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanetImage = styled.div<ImageProps>`
  width: 60%;
  padding: 0 30px;
  background-color: ${(props) => props.$color};
  mask: url(${(props) => props.$image}) no-repeat center;
  aspect-ratio: 1 / 1;
  animation: ${planetAnimation} 60s linear infinite;
`;

const PlanetInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 20px;
  padding-top: 0;
`;

const PlanetNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  gap: 5px;
`;

const PlanetName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
`;

const PlanetCurrent = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

const PlanetPassIcon = styled.div<IconProps>`
  height: 20px;
  width: 20px;
  background-color: white;
  mask: url(${(props) => props.$image}) no-repeat center;
`;

const PlanetDesc = styled.div`
  color: white;
  font-size: 16px;
  padding-bottom: 20px;
  text-align: left;
`;

const PlanetActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  margin-top: auto;
  text-align: center;
  flex-shrink: 1;
`;

export const CardButton = styled(Button)`
  margin: 0 auto;
`;

export default function PlanetCard({ planetSlug }: PlanetCardProps) {
  const dispatch = useDispatch();
  const {
    data: { location },
  } = useSelector(getCharacter);

  const planetInfo = getPlanetData(planetSlug);
  const isCurrent = location.planet === planetSlug;
  const hasPass = location.planetPasses.includes(planetSlug);
  const canTravel = !isCurrent && (planetInfo.isDefault || hasPass);

  return (
    <PlanetWrapper key={planetSlug} $color={planetInfo.color}>
      <PlanetImageWrapper>
        <PlanetImage $image={planetInfo.image} $color={planetInfo.color} />
      </PlanetImageWrapper>
      <PlanetInfo>
        <PlanetNameWrapper>
          <PlanetName>{planetInfo.name}</PlanetName>
          {hasPass && (
            <div>
              <Tooltip
                content={
                  <>
                    <TooltipName>Pass Purchased</TooltipName>
                    <TooltipDesc>
                      {`You've purchased a planet pass to travel to ${planetInfo.name}.`}
                    </TooltipDesc>
                  </>
                }
              >
                <PlanetPassIcon $image={planetPassImage} />
              </Tooltip>
            </div>
          )}
          {isCurrent && (
            <Tooltip
              content={
                <>
                  <TooltipName>Current Planet</TooltipName>
                  <TooltipDesc>
                    {`You are currently on planet ${planetInfo.name}.`}
                  </TooltipDesc>
                </>
              }
            >
              <PlanetCurrent>✦</PlanetCurrent>
            </Tooltip>
          )}
        </PlanetNameWrapper>
        <PlanetDesc>{planetInfo.description}</PlanetDesc>
        <PlanetActions>
          {!planetInfo.isDefault && (
            <CardButton
              $style="normal"
              $size="medium"
              $disabled={hasPass}
              onClick={() => {
                dispatch(buyPlanetPass({ planet: planetInfo.slug }));
              }}
            >
              Buy Pass{" "}
              <ButtonCredits>
                <ButtonCreditsIcon />
                {planetInfo.credits}
              </ButtonCredits>
            </CardButton>
          )}
          <CardButton
            $style="important"
            $size="medium"
            $disabled={!canTravel}
            onClick={() => {
              dispatch(travelToPlanet({ planet: planetInfo.slug }));
            }}
          >
            Travel to {planetInfo.name}
          </CardButton>
        </PlanetActions>
      </PlanetInfo>
    </PlanetWrapper>
  );
}
