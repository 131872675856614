import { useDispatch } from "react-redux";
import styled from "styled-components";

import { getBuildingData } from "libs/stats";
import { enterBuilding } from "redux/actions";

interface BuildingProps {
  slug: string;
}

interface BuildingWrapperProps {
  position: number;
}

const BuildingWrapper = styled.div<BuildingWrapperProps>`
  position: absolute;
  height: 100%;
  z-index: 2;
  left: ${(props) => props.position * 0.2}%;
`;

const BuildingImage = styled.img`
  height: 100%;
  cursor: pointer;
  transition: filter 100ms ease-out;

  filter: drop-shadow(0 0 40px #00ffff);

  &:hover {
    filter: drop-shadow(0 0 60px #00ffff) saturate(150%) brightness(105%);
  }
`;

export default function Building({ slug }: BuildingProps) {
  const dispatch = useDispatch();
  const building = getBuildingData(slug);

  return (
    <BuildingWrapper position={building.position}>
      <BuildingImage
        src={building.exteriorImage}
        onClick={() =>
          dispatch(
            enterBuilding({ building: slug, screen: null, dialog: null })
          )
        }
      />
    </BuildingWrapper>
  );
}
