import styled from "styled-components";

import { CHANGELOG } from "data/changelog";
import { ChangelogUpdateModel } from "types";

const ChangelogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Update = styled.div`
  background-color: #43fffa08;
  border-radius: 5px;
`;

const UpdateHeader = styled.div`
  padding: 10px;
  background-color: #43fffa10;
  display: flex;
  justify-content: space-between;
`;

const UpdateName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;

const UpdateDate = styled.div`
  opacity: 0.75;
`;

const UpdateContent = styled.ul`
  padding: 10px;
  padding-inline-start: 25px;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: disc;
  list-style-position: outside;
`;

const ChangeItem = styled.li`
  margin-bottom: 6px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export default function Changelog() {
  return (
    <ChangelogWrapper>
      {CHANGELOG.map((update: ChangelogUpdateModel) => {
        return (
          <Update>
            <UpdateHeader>
              <UpdateName>{update.name}</UpdateName>
              <UpdateDate>{update.date.toLocaleDateString()}</UpdateDate>
            </UpdateHeader>
            <UpdateContent>
              {update.changes.map((change: string) => {
                return <ChangeItem>{change}</ChangeItem>;
              })}
            </UpdateContent>
          </Update>
        );
      })}
    </ChangelogWrapper>
  );
}
