import { NPCModel } from "types";

import rookAvatarImage from "assets/npc-rook-avatar.svg";
import rookImage from "assets/npc-rook.svg";
import wickAvatarImage from "assets/npc-wick-avatar.svg";
import wickImage from "assets/npc-wick.svg";
import wittAvatarImage from "assets/npc-witt-avatar.svg";
import wittImage from "assets/npc-witt.svg";
import vegaAvatarImage from "assets/npc-vega-avatar.svg";
import vegaImage from "assets/npc-vega.svg";

interface NPCData {
  [key: string]: NPCModel;
}

export const NPCS: NPCData = {
  rook: {
    name: "Rook",
    avatar: rookAvatarImage,
    image: rookImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "This is your final launch check before you leave Bishop Pilot Academy. Click on your ship to access your stats, upgrades, skills, inventory, and account settings.",
      "Use your arrow keys to move forward and backward. Go see Conway, our pilot trainer, for a review of battle mechanics.",
      "If you ever want to change your starship class or pilot profession, you can do that at Bishop Shipworks, but know that switching costs increase with your level.",
    ],
    isDialogOpen: true,
  },
  wick: {
    name: "Wick",
    avatar: wickAvatarImage,
    image: wickImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "...Hmm? Wait, are you talking to me?",
      "Whoa, no one ever comes to me for actual visitor info... Wow, this is exciting! Shoot, I'm forgetting what I'm supposed to say, it's been so long.",
      "Okay, so hi, my name's Wick. You're currently in Bishop City, the capital city of planet Bishop. Er, that's obvious, duh.",
      "You'll find anything you need here, whether it's starship repairs, installing new starship upgrades, or pilot training.",
      "Leaving the city is a bit more dangerous. There are roving ships out in the Hollowlands that will want to fight you and destroy your ship. Which sucks.",
      "Your ship looks like it can handle a little fighting though, as rough as it looks. Oh dang, that wasn't nice, sorry. Forgot how to talk to people.",
      "Yeah, I think that's all you need to know for now!",
      "...You wanna just hang and chat or whatever?",
    ],
    isDialogOpen: false,
  },
  witt: {
    name: "Witt",
    avatar: wittAvatarImage,
    image: wittImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "...What? No, you must looking for Wick or Rook. I'm their sister, Witt.",
      "They're too chicken to come out here to the Hollowlands, ever since the Straylight clan took it over.",
      "They scare me a little too, but I do want one of those dope masks. Maybe they'll let me join one day.",
      "For now, I'll just hang out here and watch you get wrecked.",
      "I've heard beating them in battle can get you some nice upgrades for your ship.",
      "...But you're probably gonna get wrecked instead.",
    ],
    isDialogOpen: false,
  },
  vega: {
    name: "Vega",
    avatar: vegaAvatarImage,
    image: vegaImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "Welcome to Vale, the capital city of the planet Seneca. As you may have heard, Seneca is the planet of Antimatter.",
      "Literally. Our planet is made up of a single Antimatter Core that we've built our floating cities around.",
      "A lot of our residents really love the Core. Like, really, really love it. it's honestly a little culty in my opinion. ...Er, don't tell anyone I said that.",
      "Ahem, anyway, you'll find the very best of antimatter ship upgrades here, even non-reactor core parts give energy boosts.",
      "...Seriously though, don't mention the cult thing. Love you, Core.",
    ],
    isDialogOpen: false,
  },
  lyra: {
    name: "Lyra",
    avatar: vegaAvatarImage,
    image: vegaImage,
    position: 30,
    heightPercentage: 30,
    dialog: [
      "This is Haven, a sacred space in Seneca where you can especially feel the Core's presence.",
      "The guards here make sure no one disturbs these temples. No one ever does, so they get bored easily and are probably itching for a friendly fight.",
      "Friendly as in your ship will still get demolished, but they'll be nice about it.",
    ],
    isDialogOpen: false,
  },
};
