import { BATTLE_RATINGS } from "data/battleRatings";
import {
  calculateBattleRating,
  calculateDamagePercentage,
  formatDamageRatio,
} from "libs/fight";
import { getBattleRatingData } from "libs/fight";
import { useSelector } from "react-redux";
import { getCharacter, getFight } from "redux/selectors";
import styled, { css } from "styled-components";
import { BattleRatingModel } from "types";
import BattleRating from "./BattleRating";
import Tooltip, { TooltipDesc, TooltipName } from "./Tooltip";

interface RatingProgressProps {
  isFinal?: boolean;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface BarMarkerProps {
  percentage: number;
}

interface MarkerBadgeProps {
  isAchieved: boolean;
  isCurrent: boolean;
}

interface TooltipRatingProps {
  color: string;
}

const ProgressBarWrapper = styled.div`
  width: 100%;
`;

const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  position: relative;
`;

const ProgressBarMax = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff20;
  z-index: 0;
  transform: skew(-30deg);
`;

const BarValue = styled.div.attrs(({ percentage }: BarValueProps) => ({
  style: {
    width: `${percentage}%`,
  },
}))<BarValueProps>`
  background-color: ${(props) => props.color};
  height: 100%;
  transition: all 150ms linear;
  transform: skew(-30deg);
  z-index: 1;
`;

const BarTrigger = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const BarMarker = styled.div<BarMarkerProps>`
  position: absolute;
  top: 0;
  left: ${(props) => props.percentage}%;
  z-index: 3;
  height: 100%;
`;

const achievedMixin = css`
  filter: brightness(100%) drop-shadow(0px 0px 8px #00000070);
  opacity: 1;
  z-index: 4;
`;

const currentMixin = css`
  transform: translate(-50%, -50%) scale(1.5);
`;

const MarkerBadge = styled.div<MarkerBadgeProps>`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(40%);
  opacity: 0.75;
  transition: all 150ms linear;

  ${(props) => props.isAchieved && achievedMixin}
  ${(props) => props.isCurrent && currentMixin}
`;

const TooltipRating = styled.span<TooltipRatingProps>`
  color: ${(props) => props.color};
`;

export default function RatingProgress({ isFinal }: RatingProgressProps) {
  const {
    opponent: { damage: damageDealt },
  } = useSelector(getFight);
  const {
    data: { damage: damageTaken },
  } = useSelector(getCharacter);

  const currentDamageRatio = formatDamageRatio(damageDealt, damageTaken);
  const currentDamagePercentage = calculateDamagePercentage(
    damageDealt,
    damageTaken
  );
  const currentRating = calculateBattleRating(damageDealt, damageTaken);
  const currentRatingData = getBattleRatingData(currentRating);

  return (
    <ProgressBarWrapper>
      <ProgressBar>
        <ProgressBarMax />
        {Object.values(BATTLE_RATINGS)
          .reverse()
          .map((value) => {
            const ratingInfo = value as BattleRatingModel;
            const percentage = ratingInfo.damagePercentage * 100;
            const isAchieved = ratingInfo.value <= currentRatingData.value;
            const isCurrent = ratingInfo.slug === currentRatingData.slug;
            return (
              <BarMarker key={ratingInfo.slug} percentage={percentage}>
                <Tooltip
                  content={
                    <>
                      <TooltipName>{ratingInfo.name}</TooltipName>
                      <TooltipDesc>
                        This marker denotes a{" "}
                        <TooltipRating color={ratingInfo.color}>
                          {ratingInfo.name}
                        </TooltipRating>{" "}
                        rating, which means:
                      </TooltipDesc>
                      <TooltipDesc>{ratingInfo.description}</TooltipDesc>
                    </>
                  }
                >
                  <MarkerBadge isAchieved={isAchieved} isCurrent={isCurrent}>
                    <BattleRating rating={ratingInfo.slug} iconOnly noTooltip />
                  </MarkerBadge>
                </Tooltip>
              </BarMarker>
            );
          })}
        <BarValue
          percentage={currentDamagePercentage * 100}
          color={currentRatingData.color}
        />
        <BarTrigger>
          <Tooltip
            content={
              <>
                <TooltipName>Battle Rating Progress</TooltipName>
                <TooltipDesc>
                  Battle ratings are based on the ratio of damage dealt vs.
                  damage taken, calculated at the end of the battle.
                </TooltipDesc>
                <TooltipDesc>
                  {isFinal ? "Final" : "Current"} damage ratio:{" "}
                  {currentDamageRatio}
                </TooltipDesc>
                {!isFinal && (
                  <TooltipDesc>
                    You're currently on track for{" "}
                    <TooltipRating color={currentRatingData.color}>
                      {currentRatingData.name}
                    </TooltipRating>
                    .
                  </TooltipDesc>
                )}
              </>
            }
          >
            <></>
          </Tooltip>
        </BarTrigger>
      </ProgressBar>
    </ProgressBarWrapper>
  );
}
