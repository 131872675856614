import { createSelector } from "@reduxjs/toolkit";
import { DERIVED_STATS, DerivedStatInfo } from "data/derivedStats";
import { getMedallionCounts, getNextLevelExp } from "libs/character";
import { getFightTurns, getMobData, isFightModeStatus } from "libs/fight";
import { getInstalledUpgradesStats, getUpgradeData } from "libs/item";
import { getCharacterSkills, getOpponentSkills } from "libs/skill";
import {
  calculateCurrentBaseStats,
  calculateMobBaseStats,
  calculateTotalBaseStats,
  getBaseDerivedStats,
  getShipData,
  getStatRangeData,
} from "libs/stats";
import {
  CharacterBaseStats,
  CharacterData,
  CharacterDerivedStats,
  DerivedStatSets,
  Fighter,
  FightState,
  InstalledUpgradesData,
  OpponentData,
  RootState,
  ShipTypes,
  StatRanges,
} from "types";
import {
  addCharacterTurnPriority,
  addOpponentTurnPriority,
  animateAction,
  animateOpponentAction,
  attackOpponent,
  endAnimation,
  endOpponentAnimation,
  rechargeOpponentSkillsTurn,
  rechargeSkillsTurn,
  resetOpponentSkillsRecharge,
  resetOpponentSkillsValues,
  resetSkillsRecharge,
  resetSkillsValues,
  restoreOpponentPart,
  restorePart,
  setOpponentSkillRecharge,
  setOpponentSkillValue,
  setSkillRecharge,
  setSkillValue,
  takeDamage,
  weakenOpponentPart,
  weakenPart,
} from "./actions";
import { initialFightState } from "./reducers";
import { healSaga } from "./sagas/character";
import { opponentHealSaga } from "./sagas/fight";

export const CHARACTER_FIGHTER_ACTIONS = {
  setSkillRecharge: setSkillRecharge,
  rechargeSkillsTurn: rechargeSkillsTurn,
  setSkillValue: setSkillValue,
  resetSkillsRecharge: resetSkillsRecharge,
  resetSkillsValues: resetSkillsValues,
  startAnimation: animateAction,
  endAnimation: endAnimation,
  inflictDamage: attackOpponent,
  addTurnPriority: addCharacterTurnPriority,
  healSaga: healSaga,
  restorePart: restorePart,
  weakenPart: weakenPart,
};

export const OPPONENT_FIGHTER_ACTIONS = {
  setSkillRecharge: setOpponentSkillRecharge,
  rechargeSkillsTurn: rechargeOpponentSkillsTurn,
  setSkillValue: setOpponentSkillValue,
  resetSkillsRecharge: resetOpponentSkillsRecharge,
  resetSkillsValues: resetOpponentSkillsValues,
  startAnimation: animateOpponentAction,
  endAnimation: endOpponentAnimation,
  inflictDamage: takeDamage,
  addTurnPriority: addOpponentTurnPriority,
  healSaga: opponentHealSaga,
  restorePart: restoreOpponentPart,
  weakenPart: weakenOpponentPart,
};

export const getCharacter = (state: RootState) => {
  const { character, fight } = state;

  return {
    userId: character.userId,
    userName: character.userName,
    data: getFormattedCharacterData(character.data, character.userName, fight),
  };
};

export const getFormattedCharacterData = (
  characterData: CharacterData,
  userName: string | null,
  fightData: FightState
) => {
  const {
    level,
    ship,
    pilot,
    levelExp,
    enhancedBaseStats,
    trainedSkills,
    damage,
    weakenedBaseStats,
    installedUpgrades,
    skillsRecharge,
    skillsValues,
    ui,
  } = characterData;
  const { status, characterTurnPriority } = fightData;

  // BASE STATS

  // Combine ship's initial stats with earned added base stats
  const totalBaseStats = getCharacterTotalBaseStats(ship, enhancedBaseStats);

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  // DERIVED STATS

  const derivedStats = getDerivedStatSets(
    currentBaseStats,
    totalBaseStats,
    installedUpgrades
  );

  const derivedStatRanges = getDerivedStatRangeSets(derivedStats);

  // MEDALLIONS

  const medallions = getMedallionCounts(
    level,
    ship,
    enhancedBaseStats,
    trainedSkills
  );

  // SKILLS

  const skills = getCharacterSkills(
    pilot,
    trainedSkills,
    derivedStats.current.complete.fasterRecharge
  );

  // FIGHTER DATA

  const fighterData: Fighter = {
    name: userName || "You",
    data: {
      stats: derivedStats.current.complete,
      currentBaseStats,
      totalBaseStats,
      skills,
      skillsRecharge,
      skillsValues,
      damage,
      turnPriority: characterTurnPriority,
    },
    actions: CHARACTER_FIGHTER_ACTIONS,
  };

  return {
    ...characterData,
    currentLevelExp: levelExp,
    nextLevelExp: getNextLevelExp(),
    currentBaseStats,
    totalBaseStats,
    derivedStats,
    derivedStatRanges,
    shipUpgrades: getShipUpgrades(installedUpgrades),
    medallions,
    skills,
    fighterData,
    canRecall: !isFightModeStatus(status) && !ui.isTravelMode,
  };
};

export const getFormattedOpponentData = (
  opponentData: OpponentData,
  fightData: FightState
) => {
  const { slug, weakenedBaseStats, skillsRecharge, skillsValues, damage } =
    opponentData;
  const {
    name,
    level,
    images,
    dialogs,
    baseStatsModifiers,
    baseStatsCosts,
    baseStatsTargets,
    installedUpgrades,
    drops,
    isTutorial,
    skills,
  } = getMobData(slug);
  const { opponentTurnPriority } = fightData;

  // BASE STATS

  // Calculate Mob base stats on level and modifiers
  const totalBaseStats = calculateMobBaseStats(
    level,
    baseStatsModifiers,
    baseStatsCosts
  );

  // Get current base stats after weakened parts
  const currentBaseStats = calculateCurrentBaseStats(
    totalBaseStats,
    weakenedBaseStats
  );

  // DERIVED STATS

  const derivedStats = getDerivedStatSets(
    currentBaseStats,
    totalBaseStats,
    installedUpgrades
  );

  // SKILLS

  const opponentSkills = getOpponentSkills(
    skills,
    derivedStats.current.complete.fasterRecharge
  );

  // Set up opponent fighter data for fight logic
  const fighterData: Fighter = {
    name,
    data: {
      stats: derivedStats.current.complete,
      currentBaseStats,
      totalBaseStats,
      skills: opponentSkills,
      skillsRecharge,
      skillsValues,
      damage,
      turnPriority: opponentTurnPriority,
    },
    actions: OPPONENT_FIGHTER_ACTIONS,
  };

  return {
    ...opponentData,
    name,
    level,
    images,
    dialogs,
    currentBaseStats,
    totalBaseStats,
    derivedStats,
    baseStatsTargets,
    drops,
    isTutorial,
    skills: opponentSkills,
    fighterData,
  };
};

export const selectFight = (state: RootState) => {
  return state.fight;
};

export const getFight = createSelector(
  selectFight,
  getCharacter,
  (fight, character) => {
    const {
      opponent: opponentData,
      characterTurnPriority,
      opponentTurnPriority,
    } = fight;
    const opponent = getFormattedOpponentData(opponentData, fight);

    const { attackSpeed: characterAttackSpeed } =
      character.data.derivedStats.current.complete;
    const { attackSpeed: opponentAttackSpeed } =
      opponent.derivedStats.current.complete;

    const { isCharacterCurrentTurn, isCharacterNextTurn } = getFightTurns(
      characterTurnPriority,
      opponentTurnPriority,
      characterAttackSpeed,
      opponentAttackSpeed
    );

    return {
      ...fight,
      opponent,
      isCharacterCurrentTurn,
      isCharacterNextTurn,
    };
  }
);

export const getGameState = (state: RootState) => {
  return state.game;
};

export const getOnlinePlayers = (state: RootState) => {
  const { game } = state;

  const onlinePlayersData = game.onlineUsers.map((user) => {
    return {
      userName: user.userName,
      data: getFormattedCharacterData(
        user.characterData,
        user.userName,
        initialFightState
      ),
    };
  });

  return onlinePlayersData;
};

// Calculate all base and derived stats

const getCharacterTotalBaseStats = (
  ship: ShipTypes,
  enhancedBaseStats: CharacterBaseStats
) => {
  // Combine ship's initial stats with earned added base stats
  let totalBaseStats: CharacterBaseStats = enhancedBaseStats;
  const shipData = getShipData(ship);
  totalBaseStats = calculateTotalBaseStats(
    shipData.startingBaseStats,
    enhancedBaseStats
  );

  return totalBaseStats;
};

const calculateCompleteDerivedStat = (
  derivedStat: DerivedStatInfo,
  baseDerivedStat: number,
  upgradeValue: number
) => {
  return derivedStat.rounder(baseDerivedStat + upgradeValue);
};

export const getCompleteDerivedStats = (
  baseDerivedStats: CharacterDerivedStats,
  upgrades: CharacterDerivedStats
): CharacterDerivedStats => {
  const stats = Object.entries(DERIVED_STATS).reduce((acc, [key, value]) => {
    const statType = key as keyof CharacterDerivedStats;
    const statInfo = value as DerivedStatInfo;
    return {
      ...acc,
      [statType]: calculateCompleteDerivedStat(
        statInfo,
        baseDerivedStats[statType],
        upgrades[statType]
      ),
    };
  }, {} as any);

  // Make minimum attack damage always less than maximum
  if (stats.minAttackDamage > stats.maxAttackDamage) {
    stats.minAttackDamage = stats.maxAttackDamage;
  }
  // Make minimum weaken parts always less than maximum
  if (stats.minWeakenParts > stats.maxWeakenParts) {
    stats.minWeakenParts = stats.maxWeakenParts;
  }

  return stats;
};

export const getDerivedStatSets = (
  currentBaseStats: CharacterBaseStats,
  totalBaseStats: CharacterBaseStats,
  installedUpgrades: InstalledUpgradesData
): DerivedStatSets => {
  // Calculate current derived stat ranges
  const currentBaseDerivedStats = getBaseDerivedStats(currentBaseStats);
  const currentUpgradesStats = getInstalledUpgradesStats(
    installedUpgrades,
    currentBaseStats,
    totalBaseStats
  );
  const currentCompleteStats = getCompleteDerivedStats(
    currentBaseDerivedStats,
    currentUpgradesStats
  );

  // Calculate total derived stat ranges
  const totalBaseDerivedStats = getBaseDerivedStats(totalBaseStats);
  const totalUpgradesStats = getInstalledUpgradesStats(
    installedUpgrades,
    totalBaseStats,
    totalBaseStats
  );
  const totalCompleteStats = getCompleteDerivedStats(
    totalBaseDerivedStats,
    totalUpgradesStats
  );

  return {
    current: {
      base: currentBaseDerivedStats,
      upgrades: currentUpgradesStats,
      complete: currentCompleteStats,
    },
    total: {
      base: totalBaseDerivedStats,
      upgrades: totalUpgradesStats,
      complete: totalCompleteStats,
    },
  };
};

export const getStatRanges = (
  derivedStats: CharacterDerivedStats
): StatRanges => {
  const { maxAttackDamage, minAttackDamage, maxWeakenParts, minWeakenParts } =
    derivedStats;

  const attackDamageRangeInfo = getStatRangeData("attackDamageRange");
  const weakenPartsRangeInfo = getStatRangeData("weakenPartsRange");

  return {
    attackDamageRange: {
      min: attackDamageRangeInfo.rounder(minAttackDamage),
      max: attackDamageRangeInfo.rounder(maxAttackDamage),
    },
    weakenPartsRange: {
      min: weakenPartsRangeInfo.rounder(minWeakenParts),
      max: weakenPartsRangeInfo.rounder(maxWeakenParts),
    },
  };
};

export const getDerivedStatRangeSets = (statSets: DerivedStatSets) => {
  // Calculate current derived stat ranges
  const currentBaseDerivedRanges = getStatRanges(statSets.current.base);
  const currentUpgradesRanges = getStatRanges(statSets.current.upgrades);
  const currentCompleteRanges = getStatRanges(statSets.current.complete);

  // Calculate total derived stat ranges
  const totalBaseDerivedRanges = getStatRanges(statSets.total.base);
  const totalUpgradesRanges = getStatRanges(statSets.total.upgrades);
  const totalCompleteRanges = getStatRanges(statSets.total.complete);

  return {
    current: {
      base: currentBaseDerivedRanges,
      upgrades: currentUpgradesRanges,
      complete: currentCompleteRanges,
    },
    total: {
      base: totalBaseDerivedRanges,
      upgrades: totalUpgradesRanges,
      complete: totalCompleteRanges,
    },
  };
};

// UPGRADES

export const getShipUpgrades = (installedUpgrades?: InstalledUpgradesData) => {
  installedUpgrades = installedUpgrades || {
    weapons: null,
    shields: null,
    thrusters: null,
    targetingSystem: null,
    reactorCore: null,
  };

  return {
    weapons: getUpgradeData(installedUpgrades.weapons || "default_weapons"),
    shields: getUpgradeData(installedUpgrades.shields || "default_shields"),
    thrusters: getUpgradeData(
      installedUpgrades.thrusters || "default_thrusters"
    ),
    targetingSystem: getUpgradeData(
      installedUpgrades.targetingSystem || "default_targeting_system"
    ),
    reactorCore: getUpgradeData(
      installedUpgrades.reactorCore || "default_reactor_core"
    ),
  };
};
