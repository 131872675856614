import { keyframes } from "styled-components";

export const backgroundAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    filter: saturate(100%);
  }

  50% {
    transform: scale(1.2) rotate(5deg);
    filter: saturate(150%);
  }

  100% {
    transform: scale(1) rotate(0deg);
    filter: saturate(100%);
  }
`;

export const backgroundMoveRightAnimation = keyframes`
  0% {
    transform: translate3d(-10%, 0, 0);
  }

  80% {
    transform: translate3d(110%, 0, 0);
  }

  100% {
    transform: translate3d(110%, 0, 0);
  }
`;

export const backgroundMoveLeftAnimation = keyframes`
  0% {
    transform: translate3d(10%, 0, 0) scaleX(-1);
  }

  80% {
    transform: translate3d(-110%, 0, 0) scaleX(-1);
  }

  100% {
    transform: translate3d(-110%, 0, 0) scaleX(-1);
  }
`;

export const floatAnimation = keyframes`
  0% {
    transform: translate3d(0, -3%, 0);
  }

  50% {
    transform: translate3d(0, 3%, 0);
  }

  100% {
    transform: translate3d(0, -3%, 0);
  }
`;

export const wanderAnimation = keyframes`
  0% {
    transform: translate3d(20vw, 0, 0);
  }

  50% {
    transform: translate3d(-20vw, 0, 0);
  }

  100% {
    transform: translate3d(20vw, 0, 0);
  }
`;

export const wanderTurnAnimation = keyframes`
  0% {
    transform: scaleX(-1);
  }

  50% {
    transform: scaleX(-1);
  }

  50.01% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(1);
  }
`;

export const thrusterAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scale(100%);
  }

  25% {
    transform: translate3d(0, -50%, 0) scale(120%);
  }

  50% {
    transform: translate3d(0, -50%, 0) scale(105%);
  }

  75% {
    transform: translate3d(0, -50%, 0) scale(110%);
  }

  100% {
    transform: translate3d(0, -50%, 0) scale(100%);
  }
`;

export const attackAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translate3d(4000%, -50%, 0);
  }
`;

export const mobAttackAnimation = keyframes`
0% {
  opacity: 0;
  transform: translate3d(0, -50%, 0) scaleX(-1);
}

10% {
  opacity: 1;
}

90% {
  opacity: 1;
}

100% {
  opacity: 0;
  transform: translate3d(-4000%, -50%, 0) scaleX(-1);
}
`;

export const damagedAnimation = keyframes`
  0% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  20% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  40% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  50% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  60% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  80% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  90% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
  100% { transform: translate3d(1px, -2px, 0) rotate(-2deg); }
`;

export const mobDamagedAnimation = keyframes`
  0% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  20% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  40% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  50% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  60% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  70% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  80% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  90% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
  100% { transform: translate3d(1px, -2px, 0) rotate(-2deg); }
`;

export const dodgeAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -50px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const mobDodgeAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, -50px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const weakenedAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: .5;
  }

  75% {
    opacity: .5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const mobWeakenedAnimation = keyframes`
  0% {
    transform: scale(.5);
    opacity: .5;
  }

  75% {
    opacity: .5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const nullifyAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0);
    filter: brightness(100%) saturate(100%);
    
  }

  50% {
    transform: scale(1.2) rotate(90deg);
    filter: brightness(120%) saturate(200%);
  }

  100% {
    transform: scale(1) rotate(180deg);
    filter: brightness(100%) saturate(100%);
  }
`;

export const mobNullifyAnimation = keyframes`
  0% {
    transform: scale(1) rotate(0);
    filter: brightness(100%) saturate(100%);
  }

  50% {
    transform: scale(1.2) rotate(-90deg);
    filter: brightness(120%) saturate(200%);
  }

  100% {
    transform: scale(1) rotate(-180deg);
    filter: brightness(100%) saturate(100%);
  }
`;

export const escapeAnimation = keyframes`
  0% {
    transform: translateX(0%) scaleX(-1);
  }

  50% {
    transform: translateX(50%) scaleX(-1);
  }

  100% {
    transform: translateX(0%) scaleX(-1);
  }
`;

export const loseAnimation = keyframes`
  0% {
    top: 0;
  }

  5% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-1px, -2px, 0) rotate(-2deg); }
  15% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  20% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
  25% { transform: translate3d(1px, -1px, 0) rotate(2deg); }
  30% { transform: translate3d(-1px, 2px, 0) rotate(-2deg); }
  35% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  40% { transform: translate3d(3px, 1px, 0) rotate(-2deg); }
  45% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }

  50% {
    top: 200px; 
    transform: translate3d(0, 0, 0) rotate(0deg);
  }

  80% {
    top: 200px;
  }

  100% {
    top: 0;
  }
`;

export const mobLoseAnimation = keyframes`
  0% {
    top: 0;
  }

  5% { transform: translate3d(2px, 1px, 0) rotate(0deg); }
  10% { transform: translate3d(-2px, -2px, 0) rotate(-2deg); }
  15% { transform: translate3d(-3px, 0px, 0) rotate(2deg); }
  20% { transform: translate3d(3px, 4px, 0) rotate(0deg); }
  25% { transform: translate3d(-1px, -1px, 0) rotate(2deg); }
  30% { transform: translate3d(3px, 2px, 0) rotate(-2deg); }
  35% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
  40% { transform: translate3d(4px, 1px, 0) rotate(-2deg); }
  45% { transform: translate3d(-1px, -2px, 0) rotate(2deg); }
  
  50% { 
    top: 200px; 
    transform: translate3d(0, 0, 0) rotate(0deg); 
  }

  80% {
    top: 200px;
  }

  100% {
    top: 0;
  }
`;

export const pilotBounceAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const shopkeeperAnimation = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
`;
export const ratingAnimation = keyframes`
  0% {
    transform: scale(1);
    filter: brightness(100%) saturate(100%);
  }

  50% {
    transform: scale(1.05);
    filter: brightness(110%) saturate(150%);
  }

  100% {
    transform: scale(1);
    filter: brightness(100%) saturate(100%);
  }
`;

export const reactorCoreAnimation = keyframes`
  0% {
    transform: scale(0); 
    opacity: 1;
  }

  75% {
    opacity: .5;
  }
  
  100% {
    transform: scale(1); 
    opacity: 0;
  }
`;

export const shieldsAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const mobShieldsAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const targetingSystemAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scaleY(100%);
  }

  50% {
    transform: translate3d(0, -50%, 0) scaleY(150%);
  }

  100% {
    transform: translate3d(0, -50%, 0) scaleY(100%);
  }
`;

export const mobTargetingSystemAnimation = keyframes`
  0% {
    transform: translate3d(0, -50%, 0) scaleY(100%) scaleX(-1);
  }

  50% {
    transform: translate3d(0, -50%, 0) scaleY(150%) scaleX(-1);
  }

  100% {
    transform: translate3d(0, -50%, 0) scaleY(100%) scaleX(-1);
  }
`;

export const weaponsPreviewAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  30% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(500%, -50%, 0);
  }
`;

export const upgradesScreenAnimation = keyframes`
  0% {
    background-position: calc(2em + 1px) center;
  }

  100% {
    background-position: 0em center;

  }
`;

export const pilotScreenAnimation = keyframes`
  0% {
    background-position: center calc(2em + 1px);
  }

  100% {
    background-position: center 0em;
  }
`;

export const planetScreenAnimation = keyframes`
  0% {
    background-position: calc(4em + 1px) 0em;
  }

  100% {
    background-position: 0em calc(4em + 1px);
  }
`;

export const planetAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
`;

export const dialogNextAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(15%);
  }

  100% {
    transform: translateX(0);
  }
`;

export const titleAnimation = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;
