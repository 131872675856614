import { AREAS } from "data/areas";
import { CharacterBaseStats, Planets, ShipTypes, SkillSlugs } from "types";
import { getSkillsMedallionsSpent } from "./skill";
import {
  getPropertyData,
  getShipDamage,
  getStatsMedallionsSpent,
  getTotalMedallions,
} from "./stats";

export const MAX_LEVEL = 80;
export const BASE_LEVEL_EXP = 1000;
export const REPAIR_HEALTH_PER_CREDIT = 5;
export const SWITCH_CREDITS_COST_PER_LEVEL = 50;
export const LOSE_FIGHT_REPAIR_MULTIPLIER = 2;

// LEVELS

export const calculateLevelsGained = (
  currentLevelExp: number,
  expGained: number
): number => {
  const totalExp = currentLevelExp + expGained;
  const levelsGained = Math.floor(totalExp / BASE_LEVEL_EXP);

  return levelsGained;
};

export const calculateUpdatedLevelExp = (
  levelExp: number,
  expGained: number
) => {
  const propertyInfo = getPropertyData("experience");

  const newExp = levelExp + expGained;
  return propertyInfo.rounder(newExp % BASE_LEVEL_EXP);
};

export const getNextLevelExp = (): number => {
  return BASE_LEVEL_EXP;
};

export const getExpToNextLevel = (
  currentLevelExp: number,
  nextLevelExp: number
) => {
  const propertyInfo = getPropertyData("experience");

  return propertyInfo.rounder(nextLevelExp - currentLevelExp);
};

export const isMaxLevel = (level: number): boolean => {
  return level >= MAX_LEVEL;
};

// MEDALLIONS

export const getMedallionCounts = (
  level: number,
  ship: ShipTypes,
  enhancedBaseStats: CharacterBaseStats,
  trainedSkills: SkillSlugs[]
) => {
  const propertyInfo = getPropertyData("medallions");

  const totalMedallions = getTotalMedallions(level);
  const statsMedallionsSpent = getStatsMedallionsSpent(enhancedBaseStats, ship);
  const skillsMedallionsSpent = getSkillsMedallionsSpent(trainedSkills);

  const availableMedallions =
    totalMedallions - statsMedallionsSpent - skillsMedallionsSpent;

  return {
    total: totalMedallions,
    statsSpent: statsMedallionsSpent,
    skillsSpent: skillsMedallionsSpent,
    available: propertyInfo.rounder(availableMedallions),
  };
};

// SHIPWORKS

export const getRepairCost = (health: number, maxHealth: number): number => {
  const damageToRepair = getShipDamage(health, maxHealth);
  return Math.ceil(damageToRepair / REPAIR_HEALTH_PER_CREDIT);
};

export const getLoseFightRepairCost = (maxHealth: number): number => {
  return Math.round(
    Math.ceil(maxHealth / REPAIR_HEALTH_PER_CREDIT) *
      LOSE_FIGHT_REPAIR_MULTIPLIER
  );
};

export const getSwitchCost = (level: number): number => {
  const propertyInfo = getPropertyData("credits");
  const totalSwitchCost = propertyInfo.rounder(
    level * SWITCH_CREDITS_COST_PER_LEVEL
  );
  return totalSwitchCost;
};

// LOCATION

export const getSectorsByPlanet = (planet: Planets) => {
  return Object.values(AREAS)
    .filter((area) => area.planet === planet)
    .map((area) => area.slug);
};
