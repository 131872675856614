import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import expImage from "assets/exp.svg";
import BattleRatingSmall from "components/base/BattleRatingSmall";
import Dialogs from "components/base/Dialogs";
import FightStats from "components/base/FightStats";
import ItemIcon from "components/base/ItemIcon";
import {
  FightOverlayActions,
  ItemImageWrapper,
  ItemInfo,
  ItemName,
  ItemWrapper,
  ListItem,
  OverlayBox,
  OverlayButton,
  OverlayList,
  OverlaySubtitle,
  OverlayTitle,
} from "components/base/Overlay";
import Skill from "components/base/Skill";
import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import useDialog from "components/hooks/useDialog";
import { BATTLE_RATINGS } from "data/battleRatings";
import {
  getCreditsGained,
  getExpGained,
  getMobOverlayDialog,
} from "libs/fight";
import { getItemData } from "libs/item";
import { retreatFight, startFight } from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { BattleRatings } from "types";
import { COLORS } from "utils/constants";

interface IconProps {
  image: string;
}

const DialogWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`;

const FightStatsWrapper = styled.div`
  margin-bottom: 25px;
`;

const SkillsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const Skills = styled.div`
  display: flex;
  gap: 5px;
`;

const RatingWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const EarnedRating = styled.div`
  cursor: pointer;
  font-size: 18px;
`;

const EmptyItem = styled.div`
  height: 30px;
  width: 30px;
  background-color: #ffffff20;
  border-radius: 4px;
`;

const ItemRewards = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Separator = styled.div`
  opacity: 0.5;
  font-size: 10px;
`;

const Reward = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const RewardIcon = styled.div<IconProps>`
  height: 12px;
  width: 12px;
  mask: url(${(props) => props.image}) no-repeat center;
`;

const RewardExp = styled(Reward)`
  color: ${COLORS.EXPERIENCE};

  ${RewardIcon} {
    background-color: ${COLORS.EXPERIENCE};
  }
`;

const RewardCredits = styled(Reward)`
  color: ${COLORS.CREDITS};

  ${RewardIcon} {
    background-color: ${COLORS.CREDITS};
  }
`;

const RewardValue = styled.div``;

export default function FightPreview() {
  const {
    data: {
      level,
      battleRatings,
      skills: characterSkills,
      fighterData: characterFighter,
    },
  } = useSelector(getCharacter);
  const { opponent, status } = useSelector(getFight);
  const { level: mobLevel, fighterData: opponentFighter } = opponent;
  const dispatch = useDispatch();

  const activeDialog = getMobOverlayDialog(opponent.dialogs, status);

  const { currentStep, currentDialog, hasAction } = useDialog(
    activeDialog || [],
    true
  );

  const previousRating = battleRatings[opponent.slug];

  return (
    <OverlayBox>
      <OverlayTitle>Starship Analysis: {opponent.name}</OverlayTitle>
      {!!opponent.isTutorial && (
        <DialogWrapper>
          <Dialogs
            avatar={opponent.images.pilot}
            currentStep={currentStep}
            currentDialog={currentDialog}
            hasAction={hasAction}
          />
        </DialogWrapper>
      )}
      <FightStatsWrapper>
        <OverlaySubtitle>Fighter Profiles</OverlaySubtitle>
        <FightStats />
        <SkillsWrapper>
          <Skills>
            {characterSkills
              .filter((skill) => !!skill.isTrained)
              .map((skill) => {
                return (
                  <Skill
                    key={skill.slug}
                    skill={skill}
                    attacker={characterFighter}
                    defender={opponentFighter}
                    isSmall
                    isShowValues
                  />
                );
              })}
          </Skills>
          <Skills>
            {opponent.skills.map((skill) => {
              return (
                <Skill
                  key={skill.slug}
                  skill={skill}
                  attacker={opponentFighter}
                  defender={characterFighter}
                  isSmall
                  isShowValues
                />
              );
            })}
          </Skills>
        </SkillsWrapper>
      </FightStatsWrapper>
      <OverlayList>
        <OverlaySubtitle>Battle Rewards</OverlaySubtitle>
        {Object.entries(BATTLE_RATINGS).map(([key, ratingData]) => {
          const ratingKey = key as BattleRatings;
          const isEarned = previousRating === ratingKey;
          const drop = opponent.drops[ratingKey];
          const expReward = getExpGained(
            level,
            mobLevel,
            ratingData.rewardMultiplier
          );
          const creditsReward = getCreditsGained(
            mobLevel,
            ratingData.rewardMultiplier
          );
          return (
            <ListItem key={ratingKey}>
              <ItemRewards>
                {drop ? (
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <ItemIcon slug={drop.slug} />
                    </ItemImageWrapper>
                    <ItemName>{getItemData(drop.slug).name}</ItemName>
                  </ItemWrapper>
                ) : (
                  <ItemWrapper>
                    <ItemImageWrapper>
                      <EmptyItem />
                    </ItemImageWrapper>
                    <ItemName>None</ItemName>
                  </ItemWrapper>
                )}
                <Separator>◆</Separator>
                <Tooltip
                  content={
                    <>
                      <TooltipName>Reward: Experience</TooltipName>
                      <TooltipDesc>
                        Experience is rewarded based on your level vs. your
                        opponent's level, amplified by your battle rating.
                      </TooltipDesc>
                    </>
                  }
                >
                  <RewardExp>
                    <RewardIcon image={expImage} />
                    <RewardValue>{expReward}</RewardValue>
                  </RewardExp>
                </Tooltip>
                <Separator>◆</Separator>
                <Tooltip
                  content={
                    <>
                      <TooltipName>Reward: Credits</TooltipName>
                      <TooltipDesc>
                        Credits is rewarded based on your opponent's level,
                        amplified by your battle rating.
                      </TooltipDesc>
                    </>
                  }
                >
                  <RewardCredits>
                    <RewardIcon image={creditsImage} />
                    <RewardValue>{creditsReward}</RewardValue>
                  </RewardCredits>
                </Tooltip>
              </ItemRewards>
              <ItemInfo>
                <RatingWrapper>
                  {!!previousRating && !!isEarned && (
                    <Tooltip
                      content={
                        <>
                          <TooltipName>Highest Previous Rating</TooltipName>
                          <TooltipDesc>
                            {`${ratingData.name} is the highest battle rating
                        you've earned on ${opponent.name} in previous battles.`}
                          </TooltipDesc>
                        </>
                      }
                    >
                      <EarnedRating>✦</EarnedRating>
                    </Tooltip>
                  )}
                  <BattleRatingSmall rating={ratingKey} />
                </RatingWrapper>
              </ItemInfo>
            </ListItem>
          );
        })}
      </OverlayList>
      <FightOverlayActions>
        <OverlayButton
          $style="normal"
          $size="large"
          onClick={() => dispatch(retreatFight())}
        >
          Retreat
        </OverlayButton>
        <OverlayButton
          $style="important"
          $size="large"
          onClick={() => dispatch(startFight())}
        >
          Battle
        </OverlayButton>
      </FightOverlayActions>
    </OverlayBox>
  );
}
