import { useEffect, useRef } from "react";
import styled from "styled-components";

import discordImage from "assets/discord-mark-white.svg";
import reactionImage from "assets/message-reaction-icon.svg";
import {
  DataWrapper,
  highlightMixin,
  screenItemMixin,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import OnlinePlayers from "components/ui/OnlinePlayers";
import { useDispatch, useSelector } from "react-redux";
import { reactToLogMessage } from "redux/actions";
import { getGameState } from "redux/selectors";
import { LogMessage } from "types";

const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${screenItemMixin}
  padding: 6px 0;
`;

const ChatDisplay = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(84vh - 190px);
  overflow-y: scroll;
`;

const MessageMain = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 0;
  flex-grow: 1;
`;

const MessageInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const MessageTime = styled.div`
  opacity: 0.75;
  font-size: 12px;
  white-space: nowrap;
`;

const MessageText = styled.div``;

const MessageReactionsWrapper = styled.div`
  height: 24px;
`;

const MessageReactions = styled.div<{ hasReactions: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #2da5b2;
  height: 24px;
  cursor: pointer;
  transition: all 200ms ease-in;

  opacity: ${({ hasReactions }) => (hasReactions ? 1 : 0)};

  &:hover {
    opacity: 1;
    filter: saturate(1.5) brightness(1.5);
  }
`;

const ReactionIcon = styled.img`
  height: 24px;
`;

const Message = styled.div`
  padding: 0 10px;
  display: flex;
  gap: 4px;
  transition: all 200ms ease-in-out;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: #ffffff10;
    ${MessageReactions} {
      opacity: 1;
    }
  }
`;

const ChatLatest = styled.div``;

const DiscordLink = styled.a`
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: left;
  position: static;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  display: flex;
  align-items: center;
  gap: 10px;
  ${highlightMixin}

  :hover {
    background-color: #43fffa20;
  }
`;

const TalkIcon = styled.img`
  height: 20px;
`;

export default function Comms() {
  const dispatch = useDispatch();

  const chatRef = useRef<HTMLDivElement>(null);

  const { logMessages } = useSelector(getGameState);

  // Scroll to newest message on load, and when new messages are sent
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    }
  }, [chatRef, logMessages]);

  const handleClickReactions = (message: LogMessage) => {
    dispatch(reactToLogMessage(message));
  };

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Active Starships</SectionTitle>
        <SectionContentScroll>
          <OnlinePlayers />
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Transmissions</SectionTitle>
        <SectionContent>
          <ChatWrapper>
            <ChatDisplay>
              {!!logMessages &&
                logMessages.map((message: LogMessage, index) => {
                  const datetime = new Date(message.time);
                  const messageDate = datetime
                    .toLocaleDateString([], {
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .replace(/\//g, ".");
                  const messageTime = datetime.toLocaleTimeString([], {
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  return (
                    <Message key={index}>
                      <MessageMain>
                        <MessageInfo>
                          <MessageTime>
                            {messageDate}/{messageTime}
                          </MessageTime>
                        </MessageInfo>
                        <MessageText>{message.text}</MessageText>
                      </MessageMain>
                      <MessageReactionsWrapper>
                        <Tooltip
                          content={
                            <>
                              <TooltipName>Click to React</TooltipName>
                              {message.reactions.length > 0 && (
                                <TooltipDesc>
                                  Reactions from{" "}
                                  {message.reactions
                                    .map((reaction) => reaction.senderName)
                                    .join(", ")}
                                </TooltipDesc>
                              )}
                            </>
                          }
                        >
                          <MessageReactions
                            hasReactions={!!message.reactions.length}
                            onClick={() => handleClickReactions(message)}
                          >
                            {!!message.reactions.length &&
                              message.reactions.length}
                            <ReactionIcon src={reactionImage} />
                          </MessageReactions>
                        </Tooltip>
                      </MessageReactionsWrapper>
                    </Message>
                  );
                })}
              <ChatLatest ref={chatRef} />
            </ChatDisplay>
          </ChatWrapper>
          <DiscordLink href="https://discord.gg/eCuSGRqGry" target="_blank">
            <TalkIcon src={discordImage} />
            Come join the convo on Discord!
          </DiscordLink>
        </SectionContent>
      </Section>
    </DataWrapper>
  );
}
