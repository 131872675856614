import { useDispatch, useSelector } from "react-redux";

import {
  SettingButtons,
  SettingName,
  SettingsWrapper,
  SettingValue,
  SettingWrapper,
} from "components/base/Settings";
import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import {
  setAuthOverlayScreen,
  showAuthOverlay,
  signOutUserAccount,
} from "redux/actions";
import { getGameState } from "redux/selectors";
import styled from "styled-components";
import Button from "components/base/Button";

interface LinkStatusProps {
  $isLinked: boolean;
}

const LinkStatus = styled.div<LinkStatusProps>`
  color: ${(props) => (props.$isLinked ? `#43fffa` : `#ed2024`)};
`;

const TooltipWarning = styled(TooltipName)`
  color: #ed2024;
`;

export default function SettingsAuth() {
  const dispatch = useDispatch();

  const startLinkAccountFlow = () => {
    dispatch(setAuthOverlayScreen("signup"));
    dispatch(showAuthOverlay());
  };

  const { isUserAccountLinked } = useSelector(getGameState);

  return (
    <SettingsWrapper>
      <SettingWrapper>
        <SettingName>Account Status</SettingName>
        <SettingValue>
          <LinkStatus $isLinked={isUserAccountLinked}>
            {!!isUserAccountLinked ? "Linked" : "Unlinked"}
          </LinkStatus>
        </SettingValue>
      </SettingWrapper>
      <SettingWrapper>
        <SettingName>Actions</SettingName>
        <SettingButtons>
          {!isUserAccountLinked && (
            <Button
              $style="normal"
              $size="small"
              onClick={startLinkAccountFlow}
            >
              Link to Account
            </Button>
          )}
          <Tooltip
            isDisabled={isUserAccountLinked}
            content={
              <>
                <TooltipWarning>Warning</TooltipWarning>
                <TooltipDesc>
                  You are currently using a guest account. If you log out
                  without linking this account to an email, you won't be able to
                  access this account anymore.
                </TooltipDesc>
              </>
            }
          >
            <Button
              $style={!!isUserAccountLinked ? "normal" : "important"}
              $size="small"
              onClick={() => dispatch(signOutUserAccount())}
            >
              Log out
            </Button>
          </Tooltip>
        </SettingButtons>
      </SettingWrapper>
    </SettingsWrapper>
  );
}
