import { useSelector } from "react-redux";
import styled from "styled-components";

import FightStats from "components/base/FightStats";
import OpponentSkills from "components/base/OpponentSkills";
import Tooltip, { TooltipName } from "components/base/Tooltip";
import FightActions from "components/ui/FightActions";
import FightPreview from "components/ui/FightPreview";
import FightResults from "components/ui/FightResults";
import { isFightModeStatus } from "libs/fight";
import { getCharacter, getFight } from "redux/selectors";
import CombatLog from "./CombatLog";

interface FightWrapperProps {
  isShowing: boolean;
}

interface CurrentTurnProps {
  isCharacterCurrentTurn: boolean;
}

interface NextTurnProps {
  isCharacterNextTurn: boolean;
}

const FightWrapper = styled.div<FightWrapperProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  ${(props) => props.isShowing && `opacity: 1;`}
  transition: all 500ms ease-out;
`;

const FightScreen = styled.div`
  position: absolute;
  top: 5vh;
  bottom: 5vh;
  left: 5vh;
  right: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const CurrentTurnWrapper = styled.div<CurrentTurnProps>`
  position: absolute;
  z-index: 0;
  height: 100%;
  transition: all 400ms ease-out;
  ${(props) =>
    props.isCharacterCurrentTurn
      ? `left: calc(0% - 5px);`
      : `left: calc(100% - 5px - 4px);`}
`;

const CurrentTurn = styled.div`
  width: 4px;
  height: 100%;
  margin: 0 5px;
  background-color: #43fffa;
  border-radius: 2px;
`;

const NextTurnWrapper = styled.div<NextTurnProps>`
  position: absolute;
  z-index: 0;
  height: 80%;
  transition: all 400ms ease-out;
  ${(props) =>
    props.isCharacterNextTurn
      ? `left: calc(0% - 12px - 5px);`
      : `left: calc(100% + 12px - 5px - 2px);`}
`;

const NextTurn = styled.div`
  width: 2px;
  height: 100%;
  margin: 0 5px;
  background-color: #43fffa50;
  border-radius: 1px;
`;

const FightStatsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 0 24px;
  pointer-events: auto;
`;

const OpponentSkillsWrapper = styled.div`
  position: absolute;
  left: calc(100% + 25px);
`;

export default function Fight() {
  const { userName } = useSelector(getCharacter);
  const { opponent, status, isCharacterCurrentTurn, isCharacterNextTurn } =
    useSelector(getFight);

  const isFightMode = isFightModeStatus(status);
  const isResultsMode = ["winResults", "loseResults"].includes(status);

  return (
    <FightWrapper isShowing={isFightMode}>
      {status === "preview" && <FightPreview />}
      {status === "fighting" && (
        <>
          <FightScreen>
            <CombatLog />
            <FightActions />
            <FightStatsWrapper>
              <NextTurnWrapper isCharacterNextTurn={isCharacterNextTurn}>
                <Tooltip
                  content={
                    <TooltipName>
                      {`Next Turn: ${
                        isCharacterNextTurn ? userName : opponent.name
                      }`}
                    </TooltipName>
                  }
                >
                  <NextTurn />
                </Tooltip>
              </NextTurnWrapper>
              <CurrentTurnWrapper
                isCharacterCurrentTurn={isCharacterCurrentTurn}
              >
                <Tooltip
                  content={
                    <TooltipName>
                      {`Current Turn: ${
                        isCharacterCurrentTurn ? userName : opponent.name
                      }`}
                    </TooltipName>
                  }
                >
                  <CurrentTurn />
                </Tooltip>
              </CurrentTurnWrapper>
              <FightStats />
              <OpponentSkillsWrapper>
                <OpponentSkills />
              </OpponentSkillsWrapper>
            </FightStatsWrapper>
          </FightScreen>
        </>
      )}
      {isResultsMode && <FightResults />}
    </FightWrapper>
  );
}
