import {
  TooltipInfo,
  TooltipName,
  TooltipValues,
  ValuesIcon,
} from "components/base/Tooltip";
import { getBaseStatData, getDerivedStatData } from "libs/stats";
import { CharacterBaseStats, CharacterDerivedStats } from "types";

interface TooltipStatsProps {
  baseStat: keyof CharacterBaseStats;
  derivedStats: CharacterDerivedStats;
}

const TooltipStats = ({ baseStat, derivedStats }: TooltipStatsProps) => {
  const baseStatInfo = getBaseStatData(baseStat);

  return (
    <>
      <TooltipName>{baseStatInfo.name} Stats</TooltipName>
      <TooltipInfo>
        {Object.entries(derivedStats)
          .filter(([key]) => {
            const statSlug = key as keyof CharacterDerivedStats;
            const statInfo = getDerivedStatData(statSlug);
            return statInfo.baseStat === baseStat;
          })
          .map(([key, value]) => {
            const statSlug = key as keyof CharacterDerivedStats;
            const statInfo = getDerivedStatData(statSlug);
            return (
              <TooltipValues key={statSlug} color={statInfo.color}>
                <ValuesIcon image={statInfo.image} color={statInfo.color} />
                {statInfo.name}: {statInfo.formatter(value)}
              </TooltipValues>
            );
          })}
      </TooltipInfo>
    </>
  );
};

export default TooltipStats;
