import Skill from "components/base/Skill";
import { pilotScreenAnimation } from "components/styles/animations";
import { getSkillData } from "libs/skill";
import { getPilotData } from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import styled from "styled-components";
import { COLORS } from "utils/constants";
import { PilotTypes } from "types";

interface PilotCardProps {
  pilotSlug: PilotTypes;
  isLarge?: boolean;
  isSelected: boolean;
  onClick?: (pilot: PilotTypes) => void;
}

interface PilotWrapperProps {
  $isSelected: boolean;
}

interface SizeProps {
  $isLarge: boolean;
}

const PilotWrapper = styled.div<PilotWrapperProps>`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;
  background-color: #00000099;
  background-size: 1em 1em;
  background-position: center center;
  background-image: linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${pilotScreenAnimation} 2s linear infinite;
  border: 1px #ffffff90 solid;

  :hover {
    background-color: #2da5b220;
    border-color: #2da5b2;
  }

  ${(props) =>
    props.$isSelected &&
    `
    background-color: #2da5b220;
    border-color: #2da5b2;
    border-width: 4px;
    margin: -3px;
  `}
`;

const PilotImageWrapper = styled.div`
  padding: 20px;
  padding-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PilotImage = styled.img`
  width: 60%;
  padding: 0 30px;
  box-shadow: 0 4px 6px -6px white;
`;

const PilotInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 20px;
  padding-top: 0;
`;

const PilotName = styled.div<SizeProps>`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: ${COLORS.PILOTS};
  text-align: center;
  flex-basis: 60px;

  ${(props) => props.$isLarge && `flex-basis: auto;`}
`;

const PilotDesc = styled.div<SizeProps>`
  color: white;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: left;

  ${(props) => props.$isLarge && `font-size: 16px;`}
`;

const PilotSkills = styled.div`
  color: white;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
`;

const SkillsTitle = styled.div<SizeProps>`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
  color: #2da5b2;

  ${(props) => props.$isLarge && `font-size: 20px;`}
`;

const SkillWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const SkillName = styled.div<SizeProps>`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  ${(props) => props.$isLarge && `font-size: 16px;`}
`;

export default function PilotCard({
  pilotSlug,
  isLarge = false,
  isSelected,
  onClick,
}: PilotCardProps) {
  const pilotInfo = getPilotData(pilotSlug);
  const {
    data: { fighterData: characterFighter },
  } = useSelector(getCharacter);

  return (
    <PilotWrapper
      key={pilotSlug}
      $isSelected={isSelected}
      onClick={() => onClick && onClick(pilotSlug)}
    >
      <PilotImageWrapper>
        <PilotImage src={pilotInfo.images.avatar} />
      </PilotImageWrapper>
      <PilotInfo>
        <PilotName $isLarge={isLarge}>{pilotInfo.name}</PilotName>
        <PilotDesc $isLarge={isLarge}>{pilotInfo.description}</PilotDesc>
        <PilotSkills>
          <SkillsTitle $isLarge={isLarge}>Starting Skill</SkillsTitle>
          {pilotInfo.skills
            .filter((skill) => {
              const skillInfo = getSkillData(skill);
              return !!skillInfo.isDefault;
            })
            .map((skill) => {
              const skillInfo = getSkillData(skill);
              return (
                <SkillWrapper key={skill}>
                  <Skill skill={skillInfo} attacker={characterFighter} />
                  <SkillName $isLarge={isLarge}>{skillInfo.name}</SkillName>
                </SkillWrapper>
              );
            })}
        </PilotSkills>
      </PilotInfo>
    </PilotWrapper>
  );
}
