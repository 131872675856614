import { AreaModel, PlanetModel } from "types";

import planetBishopImage from "assets/planet-bishop.svg";
import planetSenecaImage from "assets/planet-seneca.svg";

// Sector Backgrounds
import bgFixedImageLaunchCheck from "assets/bg-fixed-launch-check.svg";
import bgSlowImageLaunchCheck from "assets/bg-slow-launch-check.svg";
import bgMovingImageLaunchCheck from "assets/bg-moving-launch-check.svg";
import fgImageLaunchCheck from "assets/fg-launch-check.svg";
import bgFixedImageBishopCity from "assets/bg-fixed-meteora.svg";
import bgSlowImageBishopCity from "assets/bg-slow-meteora.svg";
import bgMovingImageBishopCity from "assets/bg-moving-meteora.svg";
import fgImageBishopCity from "assets/fg-meteora.svg";
import bgFixedImageHollowlands from "assets/bg-fixed-hollowlands.svg";
import bgSlowImageHollowlands from "assets/bg-slow-hollowlands.svg";
import bgMovingImageHollowlands from "assets/bg-moving-hollowlands.svg";
import fgImageHollowlands from "assets/fg-hollowlands.svg";
import bgFixedImageStraylightEnclave from "assets/bg-fixed-straylight-enclave.svg";
import bgSlowImageStraylightEnclave from "assets/bg-slow-straylight-enclave.svg";
import bgMovingImageStraylightEnclave from "assets/bg-moving-straylight-enclave.svg";
import fgImageStraylightEnclave from "assets/fg-straylight-enclave.svg";
import bgFixedImageDashPort from "assets/bg-fixed-dash-port.svg";
import bgSlowImageDashPort from "assets/bg-slow-dash-port.svg";
import fgImageDashPort from "assets/fg-dash-port.svg";
import bgFixedImageVale from "assets/bg-fixed-vale.svg";
import bgSlowImageVale from "assets/bg-slow-vale.svg";
import bgMovingImageVale from "assets/bg-moving-vale.svg";
import fgImageVale from "assets/fg-vale.svg";
import bgFixedImageSanctum from "assets/bg-fixed-sanctum.svg";
import bgSlowImageSanctum from "assets/bg-slow-sanctum.svg";
import bgMovingImageSanctum from "assets/bg-moving-sanctum.svg";
import bgFixedImageUnderhaven from "assets/bg-fixed-underhaven.svg";
import bgSlowImageUnderhaven from "assets/bg-slow-underhaven.svg";
import bgMovingImageUnderhaven from "assets/bg-moving-underhaven.svg";
import bgFixedImageCoreHorizon from "assets/bg-fixed-core-horizon.svg";
import bgSlowImageCoreHorizon from "assets/bg-slow-core-horizon.svg";

// Background Ships
import bgShipTransporterImage from "assets/bg-ship-transporter.svg";
import bgShipTaxiImage from "assets/bg-ship-taxi.svg";
import bgShipSpeederImage from "assets/bg-ship-speeder.svg";
import bgShipTramImage from "assets/bg-ship-tram.svg";
import bgShipVanImage from "assets/bg-ship-van.svg";
import bgShipTransporterRedImage from "assets/bg-ship-transporter-red.svg";
import bgShipTaxiRedImage from "assets/bg-ship-taxi-red.svg";
import bgShipSpeederRedImage from "assets/bg-ship-speeder-red.svg";
import bgShipTransporterWhiteImage from "assets/bg-ship-transporter-white.svg";
import bgShipTaxiWhiteImage from "assets/bg-ship-taxi-white.svg";
import bgShipSpeederWhiteImage from "assets/bg-ship-speeder-white.svg";
import bgShipTransporterSenecaImage from "assets/bg-ship-transporter-seneca.svg";
import bgShipTramSenecaImage from "assets/bg-ship-tram-seneca.svg";
import bgShipSpeederSenecaImage from "assets/bg-ship-speeder-seneca.svg";
import { COLORS } from "utils/constants";

export interface PlanetData {
  [key: string]: PlanetModel;
}

interface AreaData {
  [key: string]: AreaModel;
}

const DEFAULT_PLANET_CREDITS = 3000;

export const PLANETS: PlanetData = {
  bishop: {
    slug: "bishop",
    name: "Bishop",
    description:
      "Bishop is the central hub planet of this corner of the universe. No matter your starship class or pilot profession, you'll find something useful here. There is the problem of that pesky Straylight clan who run the areas outside of the main city. Dope masks, though.",
    image: planetBishopImage,
    color: COLORS.LOCATION,
    mainArea: "bishopCity",
    linkSectors: ["bishopCity", "dashPort"],
    playlist: "bishop",
    credits: 0,
    isDefault: true,
  },
  seneca: {
    slug: "seneca",
    name: "Seneca",
    description:
      "Seneca is all about Antimatter energy. If you want the best in reactor cores to boost your pilot skills, Seneca has them. The planet itself consists of just a powerful antimatter core with no actual surface. The core powers all of the floating cities that were built around it, so residents of Seneca really love the core. Like a deep, slightly alarming love.",
    image: planetSenecaImage,
    color: COLORS.ENERGY,
    mainArea: "vale",
    linkSectors: ["vale", "core_horizon"],
    playlist: "seneca",
    credits: DEFAULT_PLANET_CREDITS,
    isDefault: false,
  },
};

export const AREAS: AreaData = {
  launchCheck: {
    slug: "launchCheck",
    name: "Launch Check",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageLaunchCheck,
    backgroundSlowImage: bgSlowImageLaunchCheck,
    backgroundMovingImage: bgMovingImageLaunchCheck,
    foregroundImage: fgImageLaunchCheck,
    backgroundFixedShips: [],
    backgroundSlowShips: [],
    backgroundMovingShips: [],
    npcs: ["rook"],
    mobs: ["conway"],
    buildings: [],
    moveRange: 80,
    areaLeft: null,
    areaRight: "bishopCity",
  },
  bishopCity: {
    slug: "bishopCity",
    name: "Bishop City",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageBishopCity,
    backgroundSlowImage: bgSlowImageBishopCity,
    backgroundMovingImage: bgMovingImageBishopCity,
    foregroundImage: fgImageBishopCity,
    backgroundFixedShips: [
      {
        image: bgShipTramImage,
        duration: 15,
        height: 6,
        verticalPosition: 18,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipVanImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: false,
      },
      {
        image: bgShipTramImage,
        duration: 35,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederImage,
        duration: 25,
        height: 10,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipTramImage,
        duration: 45,
        height: 10,
        verticalPosition: 50,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTramImage,
        duration: 25,
        height: 18,
        verticalPosition: 50,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederImage,
        duration: 15,
        height: 18,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipTaxiImage,
        duration: 20,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterImage,
        duration: 22,
        height: 18,
        verticalPosition: 15,
        isMovingRight: false,
      },
      {
        image: bgShipVanImage,
        duration: 18,
        height: 18,
        verticalPosition: 80,
        isMovingRight: true,
      },
    ],
    npcs: ["wick"],
    mobs: [],
    buildings: ["bishopJumpGate", "bishopShipworks", "bishopPilotAcademy"],
    shipworks: "bishopShipworks",
    moveRange: 180,
    areaLeft: "launchCheck",
    areaRight: "hollowlands",
  },
  hollowlands: {
    slug: "hollowlands",
    name: "Hollowlands",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageHollowlands,
    backgroundSlowImage: bgSlowImageHollowlands,
    backgroundMovingImage: bgMovingImageHollowlands,
    foregroundImage: fgImageHollowlands,
    backgroundFixedShips: [
      {
        image: bgShipSpeederRedImage,
        duration: 10,
        height: 6,
        verticalPosition: 30,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterRedImage,
        duration: 16,
        height: 6,
        verticalPosition: 25,
        isMovingRight: true,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiRedImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterRedImage,
        duration: 45,
        height: 10,
        verticalPosition: 15,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterRedImage,
        duration: 22,
        height: 18,
        verticalPosition: 25,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederRedImage,
        duration: 14,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTaxiRedImage,
        duration: 20,
        height: 18,
        verticalPosition: 50,
        isMovingRight: false,
      },
    ],
    npcs: ["witt"],
    mobs: ["gren", "ardin", "sharper", "cross", "kether", "gamma", "lockheed"],
    buildings: [],
    moveRange: 250,
    areaLeft: "bishopCity",
    areaRight: "straylightEnclave",
  },
  straylightEnclave: {
    slug: "straylightEnclave",
    name: "Strayhold",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageStraylightEnclave,
    backgroundSlowImage: bgSlowImageStraylightEnclave,
    backgroundMovingImage: bgMovingImageStraylightEnclave,
    foregroundImage: fgImageStraylightEnclave,
    backgroundFixedShips: [
      {
        image: bgShipSpeederWhiteImage,
        duration: 12,
        height: 6,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 18,
        height: 6,
        verticalPosition: 35,
        isMovingRight: true,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiWhiteImage,
        duration: 40,
        height: 10,
        verticalPosition: 35,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 45,
        height: 10,
        verticalPosition: 55,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterWhiteImage,
        duration: 21,
        height: 18,
        verticalPosition: 15,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederWhiteImage,
        duration: 14,
        height: 18,
        verticalPosition: 40,
        isMovingRight: false,
      },
      {
        image: bgShipTaxiWhiteImage,
        duration: 20,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: [],
    mobs: ["rincon", "hadron", "acela", "megama", "breeden", "revin", "valera"],
    buildings: [],
    moveRange: 250,
    areaLeft: "hollowlands",
    areaRight: "dashPort",
  },
  dashPort: {
    slug: "dashPort",
    name: "Dash Port",
    planet: "bishop",
    backgroundFixedImage: bgFixedImageDashPort,
    backgroundSlowImage: bgSlowImageDashPort,
    backgroundMovingImage: "",
    foregroundImage: fgImageDashPort,
    backgroundFixedShips: [
      {
        image: bgShipSpeederWhiteImage,
        duration: 12,
        height: 6,
        verticalPosition: 70,
        isMovingRight: false,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 18,
        height: 6,
        verticalPosition: 35,
        isMovingRight: true,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTaxiWhiteImage,
        duration: 40,
        height: 10,
        verticalPosition: 35,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterWhiteImage,
        duration: 45,
        height: 10,
        verticalPosition: 55,
        isMovingRight: false,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterWhiteImage,
        duration: 21,
        height: 18,
        verticalPosition: 15,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederWhiteImage,
        duration: 14,
        height: 18,
        verticalPosition: 40,
        isMovingRight: false,
      },
      {
        image: bgShipTaxiWhiteImage,
        duration: 20,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: [],
    mobs: [],
    buildings: ["bishopVectorStation"],
    moveRange: 60,
    areaLeft: "straylightEnclave",
    areaRight: null,
  },
  vale: {
    slug: "vale",
    name: "Vale",
    planet: "seneca",
    backgroundFixedImage: bgFixedImageVale,
    backgroundSlowImage: bgSlowImageVale,
    backgroundMovingImage: bgMovingImageVale,
    foregroundImage: fgImageVale,
    backgroundFixedShips: [
      {
        image: bgShipTramSenecaImage,
        duration: 15,
        height: 6,
        verticalPosition: 15,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterSenecaImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 35,
        height: 10,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 25,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 21,
        height: 18,
        verticalPosition: 46,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 14,
        height: 18,
        verticalPosition: 24,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 25,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: ["vega"],
    mobs: [],
    buildings: ["senecaShipworks", "senecaPilotAcademy", "senecaJumpGate"],
    shipworks: "senecaShipworks",
    moveRange: 180,
    areaLeft: null,
    areaRight: "sanctum",
  },
  sanctum: {
    slug: "sanctum",
    name: "Haven",
    planet: "seneca",
    backgroundFixedImage: bgFixedImageSanctum,
    backgroundSlowImage: bgSlowImageSanctum,
    backgroundMovingImage: bgMovingImageSanctum,
    foregroundImage: "",
    backgroundFixedShips: [
      {
        image: bgShipTramSenecaImage,
        duration: 15,
        height: 6,
        verticalPosition: 15,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterSenecaImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 35,
        height: 10,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 25,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 21,
        height: 18,
        verticalPosition: 46,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 14,
        height: 18,
        verticalPosition: 24,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 25,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: ["lyra"],
    mobs: ["zero", "aven", "spectra", "radien", "graver", "wraeth"],
    buildings: [],
    moveRange: 250,
    areaLeft: "vale",
    areaRight: "underhaven",
  },
  underhaven: {
    slug: "underhaven",
    name: "Underhaven",
    planet: "seneca",
    backgroundFixedImage: bgFixedImageUnderhaven,
    backgroundSlowImage: bgSlowImageUnderhaven,
    backgroundMovingImage: bgMovingImageUnderhaven,
    foregroundImage: "",
    backgroundFixedShips: [
      {
        image: bgShipTramSenecaImage,
        duration: 15,
        height: 6,
        verticalPosition: 15,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterSenecaImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 40,
        height: 10,
        verticalPosition: 45,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 35,
        height: 10,
        verticalPosition: 30,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 25,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 21,
        height: 18,
        verticalPosition: 46,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 14,
        height: 18,
        verticalPosition: 24,
        isMovingRight: false,
      },
      {
        image: bgShipTramSenecaImage,
        duration: 25,
        height: 18,
        verticalPosition: 70,
        isMovingRight: false,
      },
    ],
    npcs: [],
    mobs: ["arc", "vexen", "behemal", "tracer", "volta", "reaver"],
    buildings: [],
    moveRange: 250,
    areaLeft: "sanctum",
    areaRight: "core_horizon",
  },
  core_horizon: {
    slug: "core_horizon",
    name: "Core Horizon",
    planet: "seneca",
    backgroundFixedImage: bgFixedImageCoreHorizon,
    backgroundSlowImage: bgSlowImageCoreHorizon,
    backgroundMovingImage: "",
    foregroundImage: "",
    backgroundFixedShips: [
      {
        image: bgShipSpeederSenecaImage,
        duration: 10,
        height: 6,
        verticalPosition: 40,
        isMovingRight: true,
      },
      {
        image: bgShipTransporterSenecaImage,
        duration: 12,
        height: 6,
        verticalPosition: 28,
        isMovingRight: false,
      },
    ],
    backgroundSlowShips: [
      {
        image: bgShipTramSenecaImage,
        duration: 35,
        height: 10,
        verticalPosition: 30,
        isMovingRight: false,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 25,
        height: 10,
        verticalPosition: 60,
        isMovingRight: true,
      },
    ],
    backgroundMovingShips: [
      {
        image: bgShipTransporterSenecaImage,
        duration: 21,
        height: 18,
        verticalPosition: 46,
        isMovingRight: true,
      },
      {
        image: bgShipSpeederSenecaImage,
        duration: 14,
        height: 18,
        verticalPosition: 24,
        isMovingRight: false,
      },
    ],
    npcs: [],
    mobs: [],
    buildings: ["senecaVectorStation"],
    moveRange: 60,
    areaLeft: "underhaven",
    areaRight: null,
  },
};
