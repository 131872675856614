import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";

import Avatar from "components/ui/Avatar";
import { getCharacter } from "redux/selectors";
import travelStarsImage from "assets/bg-travel-mode.svg";
import travelBridgeImage from "assets/bg-travel-bridge.svg";

const TravelWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  display: flex;
  align-items: center;
`;

const bridgeAnimation = keyframes`
	0% {
    transform: scaleX(10) scaleY(.5);
  }

  60% {
    transform: scaleX(20) scaleY(3);
  }

  65% {
    transform: scaleX(20) scaleY(10);
  }

  100% {
    transform: scaleX(20) scaleY(20);
  }
`;

const BridgeBackground = styled.div`
  height: 30%;
  aspect-ratio: 1 / 1;
  margin-left: 20vw;
  overflow: hidden;
  position: absolute;
  background-image: url(${travelBridgeImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  animation: ${bridgeAnimation} 8s linear forwards;
  z-index: 0;
  transform-origin: center center;
  backface-visibility: hidden;
`;

const starsAnimation = keyframes`
  0% {
    background-position: 100vw center;
  }

  100% {
    background-position: 0 center;
  }
`;

const StarsBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("${travelStarsImage}");
  background-size: cover;
  animation: ${starsAnimation} 300ms linear infinite;
  z-index: 0;
`;

const avatarAnimation = keyframes`
	0% {
    margin-left: 20vw;
  }

  60% {
    margin-left: 20vw;
  }

  65% {
    margin-left: 60vw;
  }

  100% {
    margin-left: 100vw;
  }
`;

const AvatarWrapper = styled.div`
  height: 30%;
  margin-left: 20vw;

  animation: ${avatarAnimation} 8s linear forwards;
`;

export default function PlanetTravel() {
  const {
    data: {
      ui: { isTravelMode },
    },
  } = useSelector(getCharacter);

  if (isTravelMode) {
    return (
      <TravelWrapper>
        <BridgeBackground />
        <StarsBackground />
        <AvatarWrapper>
          <Avatar />
        </AvatarWrapper>
      </TravelWrapper>
    );
  }

  return <></>;
}
