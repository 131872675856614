import { BuildingModel } from "types";

import bishopPilotAcademyStaffImage from "assets/avatar-academy-bishop.svg";
import bishopShipworksStaffImage from "assets/avatar-shipworks-bishop.svg";
import bishopJumpGateStaffImage from "assets/avatar-jump-gate-bishop.svg";
import senecaPilotAcademyStaffImage from "assets/avatar-academy-seneca.svg";
import senecaShipworksStaffImage from "assets/avatar-shipworks-seneca.svg";
import senecaJumpGateStaffImage from "assets/avatar-jump-gate-seneca.svg";
import {
  default as bishopJumpGateInteriorImage,
  default as bishopPilotAcademyInteriorImage,
  default as bishopShipworksInteriorImage,
} from "assets/bg-shipworks-bishop.svg";
import {
  default as senecaJumpGateInteriorImage,
  default as senecaPilotAcademyInteriorImage,
  default as senecaShipworksInteriorImage,
} from "assets/bg-shipworks-seneca.svg";
import bishopJumpGateExteriorImage from "assets/building-bishop-jump-gate.svg";
import bishopPilotAcademyExteriorImage from "assets/building-bishop-pilot-academy.svg";
import bishopShipworksExteriorImage from "assets/building-bishop-shipworks.svg";
import senecaJumpGateExteriorImage from "assets/building-seneca-jump-gate.svg";
import senecaPilotAcademyExteriorImage from "assets/building-seneca-pilot-academy.svg";
import senecaShipworksExteriorImage from "assets/building-seneca-shipworks.svg";
import senecaVectorStationExteriorImage from "assets/building-seneca-vector-station.svg";
import bishopVectorStationExteriorImage from "assets/building-bishop-vector-station.svg";

interface BuildingData {
  [key: string]: BuildingModel;
}

export const BUILDINGS: BuildingData = {
  bishopShipworks: {
    type: "shipworks",
    name: "Bishop Shipworks",
    area: "bishopCity",
    position: 130,
    exteriorImage: bishopShipworksExteriorImage,
    interiorImage: bishopShipworksInteriorImage,
    staffImage: bishopShipworksStaffImage,
    staffName: "Starship Engineer Hollis",
    fullShop: "bishopShipworksShop",
    repairShop: "bishopShipworksRepair",
  },
  bishopPilotAcademy: {
    type: "pilotAcademy",
    name: "Bishop Pilot Academy",
    area: "bishopCity",
    position: 90,
    exteriorImage: bishopPilotAcademyExteriorImage,
    interiorImage: bishopPilotAcademyInteriorImage,
    staffImage: bishopPilotAcademyStaffImage,
    staffName: "Pilot Trainer Conway",
  },
  bishopJumpGate: {
    type: "jumpGate",
    name: "Bishop Jump Gate",
    area: "bishopCity",
    position: 50,
    exteriorImage: bishopJumpGateExteriorImage,
    interiorImage: bishopJumpGateInteriorImage,
    staffImage: bishopJumpGateStaffImage,
    staffName: "Warp Operator Link",
  },
  bishopVectorStation: {
    type: "vectorStation",
    name: "Bishop Vector Station",
    area: "dashPort",
    position: 30,
    exteriorImage: bishopVectorStationExteriorImage,
    interiorImage: bishopShipworksInteriorImage,
    staffImage: bishopShipworksStaffImage,
    staffName: "Rapid Tech Sinder",
    repairShop: "bishopVectorStationRepair",
  },
  senecaShipworks: {
    type: "shipworks",
    name: "Seneca Shipworks",
    area: "vale",
    position: 130,
    exteriorImage: senecaShipworksExteriorImage,
    interiorImage: senecaShipworksInteriorImage,
    staffImage: senecaShipworksStaffImage,
    staffName: "Starship Engineer Dice",
    fullShop: "senecaShipworksShop",
    repairShop: "senecaShipworksRepair",
  },
  senecaPilotAcademy: {
    type: "pilotAcademy",
    name: "Seneca Pilot Academy",
    area: "vale",
    position: 90,
    exteriorImage: senecaPilotAcademyExteriorImage,
    interiorImage: senecaPilotAcademyInteriorImage,
    staffImage: senecaPilotAcademyStaffImage,
    staffName: "Pilot Trainer Arcera",
  },
  senecaJumpGate: {
    type: "jumpGate",
    name: "Seneca Jump Gate",
    area: "vale",
    position: 50,
    exteriorImage: senecaJumpGateExteriorImage,
    interiorImage: senecaJumpGateInteriorImage,
    staffImage: senecaJumpGateStaffImage,
    staffName: "Warp Operator Dash",
  },
  senecaVectorStation: {
    type: "vectorStation",
    name: "Seneca Vector Station",
    area: "core_horizon",
    position: 30,
    exteriorImage: senecaVectorStationExteriorImage,
    interiorImage: senecaShipworksInteriorImage,
    staffImage: senecaShipworksStaffImage,
    staffName: "Rapid Tech Sinder",
    repairShop: "senecaVectorStationRepair",
  },
};
