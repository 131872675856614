import {
  DataWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import PlanetCard from "components/base/PlanetCard";
import Tooltip, {
  TooltipDesc,
  TooltipName,
} from "components/base/Tooltip";
import { planetAnimation } from "components/styles/animations";
import { getAllPlanetsData } from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import styled, { css } from "styled-components";
import { PlanetModel, Planets } from "types";
import { useImmer } from "use-immer";
import planetPassImage from "assets/icon-planet-pass.svg";

interface PlanetInfoProps {
  $color: string;
}

interface PlanetWrapperProps {
  $color: string;
  $isSelected: boolean;
}

interface ImageProps {
  $color: string;
  $image: string;
}

interface IconProps {
  $image: string;
}

interface TravelState {
  planet: Planets;
}

const PlanetList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const hoverMixin = css<PlanetWrapperProps>`
  background-color: ${(props) => props.$color}10;
`;

const selectedMixin = css<PlanetWrapperProps>`
  background-color: ${(props) => props.$color}20;
  border-color: ${(props) => props.$color};
`;

const PlanetWrapper = styled.div<PlanetWrapperProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  background-color: #43fffa06;
  border: 1px ${(props) => props.$color}40 solid;
  transition: all 200ms ease-in;

  :hover {
    ${hoverMixin}
    ${(props) => props.$isSelected && selectedMixin}
  }

  ${(props) => props.$isSelected && selectedMixin}
`;

const PlanetImageWrapper = styled.div`
  height: 80px;
  width: 80px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlanetImage = styled.div<ImageProps>`
  width: 60px;
  aspect-ratio: 1 / 1;
  background-color: ${(props) => props.$color};
  mask: url(${(props) => props.$image}) no-repeat center;
  animation: ${planetAnimation} 60s linear infinite;
`;

const PlanetInfo = styled.div<PlanetInfoProps>`
  color: ${(props) => props.$color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const PlanetName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
`;

const PlanetIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PlanetCurrent = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

const PlanetPassIcon = styled.div<IconProps>`
  height: 20px;
  width: 20px;
  background-color: white;
  mask: url(${(props) => props.$image}) no-repeat center;
`;

export default function TravelScreen() {
  const {
    data: { location },
  } = useSelector(getCharacter);

  const [state, setState] = useImmer<TravelState>({
    planet: location.planet,
  });

  const handleClickPlanet = (planet: Planets) => {
    setState((draft) => {
      draft.planet = planet;
    });
  };

  const allPlanets = getAllPlanetsData();

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Planets</SectionTitle>
        <SectionContentScroll>
          <PlanetList>
            {Object.entries(allPlanets).map(([key, value]) => {
              const planetSlug = key as Planets;
              const planetInfo = value as PlanetModel;

              const isSelected = state.planet === planetSlug;
              const isCurrent = location.planet === planetSlug;
              const hasPass = location.planetPasses.includes(planetSlug);

              return (
                <PlanetWrapper
                  key={planetSlug}
                  $color={planetInfo.color}
                  $isSelected={isSelected}
                  onClick={() => handleClickPlanet(planetSlug)}
                >
                  <PlanetImageWrapper>
                    <PlanetImage
                      $image={planetInfo.image}
                      $color={planetInfo.color}
                    />
                  </PlanetImageWrapper>
                  <PlanetInfo $color={planetInfo.color}>
                    <PlanetName>{planetInfo.name}</PlanetName>
                    <PlanetIcons>
                      {hasPass && (
                        <div>
                          <Tooltip
                            content={
                              <>
                                <TooltipName>Pass Purchased</TooltipName>
                                <TooltipDesc>
                                  {`You've purchased a planet pass to travel to ${planetInfo.name}.`}
                                </TooltipDesc>
                              </>
                            }
                          >
                            <PlanetPassIcon $image={planetPassImage} />
                          </Tooltip>
                        </div>
                      )}
                      {isCurrent && (
                        <Tooltip
                          content={
                            <>
                              <TooltipName>Current Planet</TooltipName>
                              <TooltipDesc>
                                {`You are currently on planet ${planetInfo.name}.`}
                              </TooltipDesc>
                            </>
                          }
                        >
                          <PlanetCurrent>✦</PlanetCurrent>
                        </Tooltip>
                      )}
                    </PlanetIcons>
                  </PlanetInfo>
                </PlanetWrapper>
              );
            })}
          </PlanetList>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Planet Data</SectionTitle>
        <SectionContent>
          <PlanetCard planetSlug={state.planet} />
        </SectionContent>
      </Section>
    </DataWrapper>
  );
}
