import { useSelector } from "react-redux";
import { getCharacter, getFight } from "redux/selectors";
import styled from "styled-components";
import Skill from "./Skill";

const SkillsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default function OpponentSkills() {
  const { opponent } = useSelector(getFight);
  const {
    data: { fighterData: characterFighter },
  } = useSelector(getCharacter);
  const { fighterData: opponentFighter, skills, skillsRecharge } = opponent;

  return (
    <SkillsWrapper>
      {skills.map((skill) => {
        const rechargeTurns = skillsRecharge[skill.slug] || 0;
        const totalRecharge = skill.recharge || rechargeTurns;
        const rechargePercentage = (rechargeTurns / totalRecharge) * 100;
        const isRecharging = rechargeTurns > 0;

        return (
          <Skill
            key={skill.slug}
            skill={skill}
            attacker={opponentFighter}
            defender={characterFighter}
            isSmall
            isShowValues
            isRecharging={isRecharging}
            rechargeTurns={rechargeTurns}
            rechargePercentage={rechargePercentage}
          />
        );
      })}
    </SkillsWrapper>
  );
}
