import { ShopModel } from "types";

interface ShopsData {
  [key: string]: ShopModel;
}

export const SHOPS: ShopsData = {
  bishopShipworksRepair: {
    planet: "bishop",
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
    ],
  },
  bishopShipworksShop: {
    planet: "bishop",
    upgrades: [
      "bishop_laser_one",
      "bishop_laser_two",
      "bishop_laser_three",
      "bishop_laser_four",
      "bishop_shield_one",
      "bishop_shield_two",
      "bishop_shield_three",
      "bishop_shield_four",
      "bishop_thruster_one",
      "bishop_thruster_two",
      "bishop_thruster_three",
      "bishop_thruster_four",
      "bishop_scope_one",
      "bishop_scope_two",
      "bishop_scope_three",
      "bishop_scope_four",
      "bishop_core_one",
      "bishop_core_two",
      "bishop_core_three",
      "bishop_core_four",
    ],
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
      "minor_exp_booster",
      "medium_exp_booster",
      "major_exp_booster",
      "recall_rift",
    ],
  },
  bishopVectorStationRepair: {
    planet: "bishop",
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
    ],
  },
  senecaShipworksRepair: {
    planet: "seneca",
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
    ],
  },
  senecaShipworksShop: {
    planet: "seneca",
    upgrades: [
      "seneca_core_one",
      "seneca_core_prime_one",
      "seneca_core_two",
      "seneca_core_prime_two",
      "seneca_core_three",
      "seneca_core_prime_three",
      "seneca_core_four",
      "seneca_core_prime_four",
    ],
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
      "minor_exp_booster",
      "medium_exp_booster",
      "major_exp_booster",
      "recall_rift",
    ],
  },
  senecaVectorStationRepair: {
    planet: "seneca",
    supplies: [
      "minor_repair_kit",
      "medium_repair_kit",
      "major_repair_kit",
      "minor_restore_module",
      "medium_restore_module",
      "major_restore_module",
    ],
  },
};
