import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import {
  child,
  equalTo,
  get,
  getDatabase,
  limitToLast,
  onDisconnect,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  serverTimestamp,
  set,
} from "firebase/database";
import {
  CharacterData,
  FightState,
  LogMessage,
  PilotTypes,
  ShipTypes,
} from "types";
import { ENV, getEnvironment } from "utils/server/environments";

const DATABASES = {
  [ENV.DEV]: "https://light-voyagers-dev.firebaseio.com",
  [ENV.PROD]: "https://light-voyager-default-rtdb.firebaseio.com",
};

const env = getEnvironment();
const database = DATABASES[env];

const firebaseConfig = {
  apiKey: "AIzaSyBcHkcgvJylBef3tjzNA0Qza6P-GN8Er-E",
  authDomain: "lightvoyagers.com",
  databaseURL: database,
  projectId: "light-voyager",
  storageBucket: "light-voyager.appspot.com",
  messagingSenderId: "836241672446",
  appId: "1:836241672446:web:1ac784020ad7b2c701bdaf",
  measurementId: "G-0WLQWYLH08",
};

initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

export const DB_READ_THROTTLE_THRESHOLD = 10 * 1000; // 10 seconds
export const DB_WRITE_THROTTLE_THRESHOLD = 500;
export const ERROR_MESSAGES: Record<string, string> = {
  "auth/email-already-in-use": "Your email account is already in use",
  "auth/credential-already-in-use": "Your Google account is already in use",
  "auth/invalid-email": "Please use a valid email address",
  "auth/invalid-login-credentials":
    "Invalid login - Please check your email and password",
  "auth/wrong-password": "Invalid login - Please check your email and password",
  "auth/user-not-found": "Invalid email",
  other: "Something went wrong",
};

export const LOG_MESSAGES_LIMIT = 100;

export const debounce = (callback: (...args: any[]) => void, wait: number) => {
  let timeoutId: number | undefined = undefined;

  return (...args: any[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

// REALTIME DATABASE METHODS

export const setOnlineStatus = async (userId: string) => {
  const db = getDatabase();

  const connectedRef = ref(db, ".info/connected");

  onValue(connectedRef, (snapshot) => {
    if (snapshot.val() === true) {
      const userRef = ref(db, `users/${userId}`);
      const onlineStatusRef = child(userRef, "isOnline");
      set(onlineStatusRef, true);

      // Set to false on disconnect
      onDisconnect(onlineStatusRef).set(false);
    }
  });
};

export const getUserAuth = (
  existingUserCallback: (user: User | null) => any
) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (userAuth) => {
    existingUserCallback(userAuth);
  });
};

export const signInAnonymousUser = async () => {
  const auth = getAuth();

  const userCredential = await signInAnonymously(auth);
  return userCredential.user;
};

export const signInGoogleUser = async () => {
  const auth = getAuth();

  const userCredential = await signInWithPopup(auth, googleProvider);
  return userCredential.user;
};

export const linkGoogleUserAccount = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    const userCredential = await linkWithPopup(
      auth.currentUser,
      googleProvider
    );
    return userCredential.user;
  }
};

export const signInEmailUser = async (email: string, password: string) => {
  const auth = getAuth();

  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredential.user;
};

// Create user and credentials together
export const signUpEmailUserAccount = async (
  email: string,
  password: string
) => {
  const auth = getAuth();

  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );
  return userCredential.user;
};

// Link email auth with existing anonymous user
export const linkEmailUserAccount = async (email: string, password: string) => {
  const auth = getAuth();

  if (auth.currentUser) {
    const credential = EmailAuthProvider.credential(email, password);
    const userCredential = await linkWithCredential(
      auth.currentUser,
      credential
    );
    return userCredential.user;
  } else {
    throw new Error("No existing user");
  }
};

export const signOutUser = async () => {
  const auth = getAuth();

  const result = await signOut(auth);
  return result;
};

export const removeGoogleAccount = async (user: User) => {
  const result = await deleteUser(user);
  return result;
};

export const sendResetPasswordEmail = async (email: string) => {
  const auth = getAuth();

  return await sendPasswordResetEmail(auth, email);
};

export const setUserOffline = async (userId: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  const onlineStatusRef = child(userRef, "isOnline");
  return await set(onlineStatusRef, false);
};

export const getExistingUserName = async (userName: string) => {
  const db = getDatabase();

  const userNamesRef = ref(db, `userNames`);
  const snapshot = await get(child(userNamesRef, userName.toLowerCase()));
  return snapshot.exists();
};

export const getExistingUserId = async (userId: string) => {
  const db = getDatabase();

  const usersRef = ref(db, `users`);
  const snapshot = await get(child(usersRef, userId));
  return snapshot.exists();
};

export const claimUserName = async (userId: string, userName: string) => {
  const db = getDatabase();

  const userNamesRef = ref(db, "userNames");
  return await set(child(userNamesRef, userName.toLowerCase()), userId);
};

export const createNewCharacter = async (userId: string, userName: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  return await set(child(userRef, "userName"), userName);
};

export const saveCharacterData = (
  userId: string | null,
  characterData: CharacterData,
  fightData: FightState
) => {
  if (userId) {
    const db = getDatabase();

    const userRef = ref(db, `users/${userId}`);
    set(child(userRef, "characterData"), characterData);
    set(child(userRef, "fightData"), fightData);
  }
};

export const loadUser = async (userId: string) => {
  const db = getDatabase();

  const userRef = ref(db, `users/${userId}`);
  const snapshot = await get(userRef);
  const user = snapshot.val();
  return user;
};

export const loadOnlineUsers = async () => {
  const db = getDatabase();
  const usersRef = ref(db, `users/`);
  const onlineUsersQuery = query(
    usersRef,
    orderByChild("isOnline"),
    equalTo(true)
  );

  const snapshot = await get(onlineUsersQuery);
  const onlineUsers = snapshot.val();

  return onlineUsers;
};

export const createLogMessage = async (
  userId: string,
  userName: string,
  text: string
) => {
  const db = getDatabase();

  const logMessage = {
    userId,
    userName,
    text,
    time: serverTimestamp(),
  };

  const messagesRef = ref(db, `logMessages/`);
  return await push(messagesRef, logMessage);
};

export const addReactionToLogMessage = async (
  messageId: string,
  senderName: string
) => {
  const db = getDatabase();

  const messageRef = ref(db, `logMessages/${messageId}`);

  return await push(child(messageRef, "reactions"), { senderName });
};

export const removeReactionFromLogMessage = async (
  messageId: string,
  reactionId: string
) => {
  const db = getDatabase();

  const reactionRef = ref(
    db,
    `logMessages/${messageId}/reactions/${reactionId}`
  );

  return await remove(reactionRef);
};

export const getAllLogMessages = async (
  chatCallback: (messages: LogMessage[]) => any
) => {
  const db = getDatabase();
  const messagesRef = ref(db, `logMessages/`);

  const messagesQuery = query(messagesRef, limitToLast(LOG_MESSAGES_LIMIT));

  onValue(messagesQuery, (snapshot) => {
    const data = snapshot.val() || [];
    chatCallback(data);
  });
};

export const createEventLog = async (
  userId: string,
  userName: string,
  ship: ShipTypes,
  pilot: PilotTypes,
  level: number,
  event: string,
  eventParams?: Record<string, any>
) => {
  const db = getDatabase();
  const { referrer } = document;

  const eventLog = {
    userId,
    userName,
    ship,
    pilot,
    level,
    event,
    ...eventParams,
    time: serverTimestamp(),
    referrer,
  };

  const eventLogsRef = ref(db, `eventLogs/`);
  return await push(eventLogsRef, eventLog);
};

export const getErrorMessage = (errorCode: string) => {
  const errorMessage = ERROR_MESSAGES[errorCode];

  if (!errorMessage) {
    return ERROR_MESSAGES.other;
  }

  return errorMessage;
};
