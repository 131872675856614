import { createAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import {
  AuthPayload,
  BuildingPayload,
  BuyPayload,
  PlanetPayload,
  EmploySupplyPayload,
  EnhancePayload,
  EventLogPayload,
  GetItemPayload,
  KeyboardPayload,
  LogMessage,
  LoseItemPayload,
  NewCharPayload,
  PartTypes,
  ResetPasswordPayload,
  SwitchPilotPayload,
  SwitchShipPayload,
  TrainPayload,
  MovePayload,
} from "types";
import { characterActions, fightActions, gameActions } from "./reducers";

// Character actions
export const {
  setUserId,
  setUserName,
  setShipClass,
  setPilotProfession,
  setInitialCharacterState,
  clearCharacterState,
  moveLeft,
  moveRight,
  turnShip,
  stopMove,
  disableMovement,
  enableMovement,
  moveToPosition,
  showInsideShip,
  hideInsideShip,
  switchDataScreen,
  showPlayerDetails,
  showInsideBuilding,
  hideInsideBuilding,
  switchBuildingScreen,
  setNearBoundary,
  enterArea,
  enterPlanet,
  showLinkSectorsOverlay,
  hideLinkSectorsOverlay,
  getPlanetPass,
  startTravelMode,
  endTravelMode,
  animateAction,
  endAnimation,
  resetAnimations,
  takeDamage,
  resetDamage,
  gainHealth,
  setHealth,
  weakenPart,
  restoreAllParts,
  restorePart,
  setMobBattleRating,
  setLevelExp,
  levelUp,
  gainCredits,
  loseCredits,
  getSupply,
  removeSupply,
  getUpgrade,
  removeUpgrade,
  enhanceStat,
  resetStats,
  trainSkill,
  resetSkills,
  setInstalledUpgrade,
  setUninstalledUpgrade,
  setSkillRecharge,
  rechargeSkillsTurn,
  resetSkillsRecharge,
  setSkillValue,
  resetSkillsValues,
  setMusicEnabled,
  setMusicDisabled,
} = characterActions;

// Character saga actions (no reducer)
export const validateCharacterName = createAction<string>(
  "character/validateCharacterName"
);
export const createCharacter = createAction<NewCharPayload>(
  "character/createCharacter"
);
export const loadUserData = createAction<User | null>("character/loadUserData");
export const continueAsCharacter = createAction(
  "character/continueAsCharacter"
);
export const move = createAction<MovePayload>("character/move");
export const travelToLinkSector = createAction<string>(
  "character/travelToLinkSector"
);
export const enterBuilding = createAction<BuildingPayload>(
  "character/enterBuilding"
);
export const exitBuilding = createAction("character/exitBuilding");
export const buyRepair = createAction<BuyPayload>("character/buyRepair");
export const buyRestore = createAction<BuyPayload>("character/buyRestore");
export const buyEnhanceStat = createAction<EnhancePayload>(
  "character/buyEnhanceStat"
);
export const buyResetStats = createAction<BuyPayload>(
  "character/buyResetStats"
);
export const buyTrainSkill = createAction<TrainPayload>(
  "character/buyTrainSkill"
);
export const buyResetSkills = createAction("character/buyResetSkills");
export const buySwitchShip = createAction<SwitchShipPayload>(
  "character/buySwitchShip"
);
export const buySwitchPilot = createAction<SwitchPilotPayload>(
  "character/buySwitchPilot"
);
export const buyShopItem = createAction<GetItemPayload>(
  "character/buyShopItem"
);
export const sellShopItem = createAction<LoseItemPayload>(
  "character/sellShopItem"
);
export const buyPlanetPass = createAction<PlanetPayload>(
  "character/buyPlanetPass"
);
export const travelToPlanet = createAction<PlanetPayload>(
  "character/travelToPlanet"
);
export const employSupply = createAction<EmploySupplyPayload>(
  "character/employSupply"
);
export const employFightSupply = createAction<string>(
  "character/employFightSupply"
);
export const installUpgrade = createAction<string>("character/installUpgrade");
export const uninstallUpgrade = createAction<PartTypes>(
  "character/uninstallUpgrade"
);
export const enableMusic = createAction("character/enableMusic");
export const disableMusic = createAction("character/disableMusic");

// Game actions
export const {
  setInitialDataLoaded,
  setGameModeActive,
  setGameModeInactive,
  setNewUser,
  setMainMenuScreen,
  setNewCharacterScreen,
  setUserAccountLinked,
  setUserAccountUnlinked,
  showNameSelectMessage,
  showMessage,
  setOnlineUsers,
  hideWorld,
  showWorld,
  showAuthOverlay,
  hideAuthOverlay,
  setAuthOverlayScreen,
  setLogMessages,
  startMusicPlayer,
  stopMusicPlayer,
  setCurrentTrack,
  setTrackChange,
  setCurrentPlaylist,
} = gameActions;

// Game saga actions (no reducer)
export const keyboardCommands = createAction<KeyboardPayload>(
  "game/keyboardCommands"
);
export const signInGoogleAccount = createAction("game/signInGoogleAccount");
export const linkGoogleAccount = createAction("game/linkGoogleAccount");
export const signInEmailAccount = createAction<AuthPayload>(
  "game/signInEmailAccount"
);
export const signUpEmailAccount = createAction<AuthPayload>(
  "game/signUpEmailAccount"
);
export const linkEmailAccount = createAction<AuthPayload>(
  "game/linkEmailAccount"
);
export const signOutUserAccount = createAction("game/signOutUserAccount");
export const resetPassword =
  createAction<ResetPasswordPayload>("game/resetPassword");
export const exitToMainMenu = createAction("game/exitToMainMenu");
export const getOnlineUsers = createAction("game/getOnlineUsers");
export const enterShip = createAction("game/enterShip");
export const exitShip = createAction("game/exitShip");
export const viewPlayerShip = createAction<string | null>(
  "game/viewPlayerShip"
);
export const addLogMessage = createAction<string>("game/addLogMessage");
export const reactToLogMessage = createAction<LogMessage>(
  "game/reactToLogMessage"
);
export const getLogMessages = createAction<LogMessage[]>("game/getLogMessages");
export const addEventLog = createAction<EventLogPayload>("game/addEventLog");
export const playMusic = createAction("game/playMusic");
export const pauseMusic = createAction("game/pauseMusic");
export const playNextMusicTrack = createAction("game/playNextMusicTrack");
export const changePlanetPlaylist = createAction("game/changePlanetPlaylist");

// Fight actions
export const {
  setInitialFightState,
  clearFightState,
  setFightStatus,
  setupOpponent,
  addCharacterTurnPriority,
  addOpponentTurnPriority,
  resetTurnPriorities,
  enableFightActions,
  disableFightActions,
  attackOpponent,
  setOpponentHealth,
  healOpponent,
  restoreOpponentPart,
  weakenOpponentPart,
  setOpponentSkillRecharge,
  rechargeOpponentSkillsTurn,
  resetOpponentSkillsRecharge,
  setOpponentSkillValue,
  resetOpponentSkillsValues,
  resetOpponentDamage,
  resetOpponentParts,
  animateOpponentAction,
  endOpponentAnimation,
  resetOpponentAnimations,
  setFightResults,
  resetFightResults,
  addCombatLog,
  resetCombatLogs,
  openCombatLog,
  closeCombatLog,
} = fightActions;

// Fight saga actions (no reducer)
export const startMobDialog = createAction<string>("fight/startMobDialog");
export const previewFight = createAction("fight/previewFight");
export const retreatFight = createAction("fight/retreatFight");
export const startFight = createAction("fight/startFight");
export const continueFight = createAction("fight/continueFight");
export const fightAgain = createAction("fight/fightAgain");
export const targetWeapons = createAction("fight/targetWeapons");
export const targetShields = createAction("fight/targetShields");
export const targetThrusters = createAction("fight/targetThrusters");
export const targetTargetingComputer = createAction(
  "fight/targetTargetingComputer"
);
export const targetReactor = createAction("fight/targetReactor");
export const escapeFight = createAction("fight/escapeFight");
export const onwardFightWin = createAction("fight/onwardFightWin");
export const endFightWin = createAction("fight/endFightWin");
export const onwardFightLose = createAction("fight/onwardFightLose");
export const endFightLose = createAction("fight/endFightLose");

// Skills
export const activateSkill = createAction<string>("skill/activateSkill");
export const tripleShot = createAction("skill/tripleShot");
