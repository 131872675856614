import { useSelector } from "react-redux";
import styled from "styled-components";
import { COLORS } from "utils/constants";

import medallionsImage from "assets/enhancements.svg";
import Tooltip from "components/base/Tooltip";
import { getCharacter } from "redux/selectors";
import TooltipMedallions from "./TooltipMedallions";
import { highlightMixin } from "./DataScreen";

interface IconProps {
  image: string;
}

const MedallionsWrapper = styled.div`
  ${highlightMixin}
  margin-bottom: 10px;
`;

const AvailableMedallions = styled.div`
  display: flex;
  gap: 5px;
  color: ${COLORS.ENHANCEMENTS};
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const EnhancementPoints = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.ENHANCEMENTS};
  gap: 2px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const EnhancementsIcon = styled.div<IconProps>`
  width: 18px;
  height: 18px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${COLORS.ENHANCEMENTS};
`;

const Medallions = () => {
  const {
    data: { medallions },
  } = useSelector(getCharacter);

  return (
    <MedallionsWrapper>
      <Tooltip content={<TooltipMedallions />}>
        <AvailableMedallions>
          Available Medallions:
          <EnhancementPoints>
            <EnhancementsIcon image={medallionsImage} />
            {medallions.available}
          </EnhancementPoints>
        </AvailableMedallions>
      </Tooltip>
    </MedallionsWrapper>
  );
};

export default Medallions;
