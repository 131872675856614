import creditsImage from "assets/credits.svg";

import Button from "components/base/Button";
import {
  ButtonCredits,
  ButtonCreditsIcon,
  DataWrapper,
  ScreenButtonWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Tooltip, {
  CreditsIcon,
  TooltipCredits,
  TooltipDesc,
  TooltipName,
} from "components/base/Tooltip";
import { getSwitchCost } from "libs/character";
import { getAllPilotsData } from "libs/stats";
import { useDispatch, useSelector } from "react-redux";
import { buySwitchPilot } from "redux/actions";
import { getCharacter } from "redux/selectors";
import styled, { css } from "styled-components";
import { PilotModel, PilotTypes } from "types";
import { useImmer } from "use-immer";
import PilotCard from "./PilotCard";
import { COLORS } from "utils/constants";

interface PilotWrapperProps {
  $isSelected: boolean;
}

interface SwitchPilotState {
  pilot: PilotTypes;
}

const Pilots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const hoverMixin = css`
  background-color: #43fffa12;
`;

const selectedMixin = css`
  background-color: #43fffa20;
  border-color: #43fffa60;
`;

const PilotWrapper = styled.div<PilotWrapperProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  background-color: #43fffa06;
  border: 1px #43fffa20 solid;
  transition: all 200ms ease-in;

  :hover {
    ${hoverMixin}
    ${(props) => props.$isSelected && selectedMixin}
  }

  ${(props) => props.$isSelected && selectedMixin}
`;

const Pilot = styled.div`
  height: 80px;
  width: 80px;
  background-color: #ffffff10;
  border: 1px #ffffff20 solid;
  border-radius: 4px;
  overflow: hidden;
`;

const PilotImage = styled.img``;

const PilotInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const PilotName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
  color: ${COLORS.PILOTS};
`;

const PilotCurrent = styled.div`
  text-transform: uppercase;
  font-size: 24px;
  color: white;
`;

export default function SwitchPilotScreen() {
  const dispatch = useDispatch();
  const {
    data: { pilot: currentPilot, level },
  } = useSelector(getCharacter);

  const [state, setState] = useImmer<SwitchPilotState>({ pilot: currentPilot });

  const handleClickPilot = (pilot: PilotTypes) => {
    setState((draft) => {
      draft.pilot = pilot;
    });
  };

  const allPilots = getAllPilotsData();
  const isCurrentSelected = currentPilot === state.pilot;
  const switchCost = getSwitchCost(level);

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Pilot Professions</SectionTitle>
        <SectionContentScroll>
          <Pilots>
            {Object.entries(allPilots).map(([key, value]) => {
              const pilotSlug = key as PilotTypes;
              const pilotInfo = value as PilotModel;

              const isSelected = state.pilot === pilotSlug;
              const isCurrent = currentPilot === pilotSlug;

              return (
                <PilotWrapper
                  $isSelected={isSelected}
                  onClick={() => handleClickPilot(pilotSlug)}
                >
                  <Pilot>
                    <PilotImage src={pilotInfo.images.avatar} />
                  </Pilot>
                  <PilotInfo>
                    <PilotName>{pilotInfo.name}</PilotName>
                    {isCurrent && (
                      <Tooltip
                        content={
                          <>
                            <TooltipName>Current Pilot</TooltipName>
                            <TooltipDesc>
                              {`Your current pilot profession is ${pilotInfo.name}.`}
                            </TooltipDesc>
                          </>
                        }
                      >
                        <PilotCurrent>✦</PilotCurrent>
                      </Tooltip>
                    )}
                  </PilotInfo>
                </PilotWrapper>
              );
            })}
          </Pilots>
          <ScreenButtonWrapper>
            <Tooltip
              content={
                <>
                  <TooltipName>Switch Pilot Profession</TooltipName>
                  <TooltipDesc>
                    Switch to a different pilot profession. Your pilot skills
                    will also be reset and you'll get all your spent medallions
                    back.
                  </TooltipDesc>
                  <TooltipDesc>
                    Switching costs increase with your level.
                  </TooltipDesc>
                  <TooltipCredits>
                    <CreditsIcon image={creditsImage} />
                    {`Credits: ${switchCost}`}
                  </TooltipCredits>
                </>
              }
            >
              <Button
                $style="normal"
                $size="medium"
                $disabled={isCurrentSelected}
                onClick={() => {
                  dispatch(buySwitchPilot({ pilot: state.pilot }));
                }}
              >
                Switch Pilot
                <ButtonCredits>
                  <ButtonCreditsIcon />
                  {switchCost}
                </ButtonCredits>
              </Button>
            </Tooltip>
          </ScreenButtonWrapper>
        </SectionContentScroll>
      </Section>
      <Section>
        <SectionTitle>Selected Pilot Profession</SectionTitle>
        <SectionContent>
          <PilotCard pilotSlug={state.pilot} isLarge isSelected />
        </SectionContent>
      </Section>
    </DataWrapper>
  );
}
