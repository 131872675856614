import { useSelector } from "react-redux";
import styled from "styled-components";

import WeakenBar from "components/base/WeakenBar";
import { BASE_STATS_INFO } from "data/baseStats";
import {
  DERIVED_STATS,
  DerivedStatInfo,
  RELATIVE_FIGHT_STATS,
  RelativeFightStatInfo,
} from "data/derivedStats";
import { getMaxBaseStatValue } from "libs/stats";
import { getCharacter, getFight } from "redux/selectors";
import {
  CharacterBaseStats,
  CharacterDerivedStats,
  RelativeFightStats,
} from "types";
import Tooltip, {
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipValues,
  TotalValue,
  ValuesIcon,
} from "./Tooltip";

interface StatIconProps {
  image: string;
  color: string;
}

const FightStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
`;

const NamesWrapper = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  color: white;
  text-transform: uppercase;
`;

const StatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BarWrapper = styled.div`
  flex-grow: 1;
`;

const StatIcon = styled.div<StatIconProps>`
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.color};
  mask: url(${(props) => props.image}) no-repeat center;
`;

export default function FightStats() {
  const {
    userName,
    data: {
      currentBaseStats,
      totalBaseStats,
      weakenedBaseStats,
      derivedStats: characterStats,
    },
  } = useSelector(getCharacter);
  const { opponent } = useSelector(getFight);

  // Pick the max value of both character and opponent as baseline
  const characterMaxValue = getMaxBaseStatValue(totalBaseStats);
  const opponentMaxValue = getMaxBaseStatValue(opponent.totalBaseStats);
  const maxValue = Math.max(characterMaxValue, opponentMaxValue);

  return (
    <FightStatsWrapper>
      <NamesWrapper>
        <div>{userName}</div>
        <div>{opponent.name}</div>
      </NamesWrapper>
      {Object.values(BASE_STATS_INFO).map((baseStat) => {
        const baseStatSlug = baseStat.slug as keyof CharacterBaseStats;
        return (
          <StatWrapper key={baseStat.slug}>
            <BarWrapper>
              <Tooltip
                content={
                  <>
                    <TooltipName>{baseStat.name} Stats</TooltipName>
                    <TooltipInfo>
                      {Object.entries(DERIVED_STATS)
                        .filter(([key, value]) => {
                          const statInfo = value as DerivedStatInfo;
                          return statInfo.baseStat === baseStatSlug;
                        })
                        .map(([key, value]) => {
                          const statSlug = key as keyof CharacterDerivedStats;
                          const statInfo = value as DerivedStatInfo;
                          const currentStat =
                            characterStats.current.complete[statSlug];
                          const totalStat =
                            characterStats.total.complete[statSlug];
                          const shouldShowTotal = currentStat !== totalStat;

                          return (
                            <TooltipValues
                              key={statSlug}
                              color={statInfo.color}
                            >
                              <ValuesIcon
                                image={statInfo.image}
                                color={statInfo.color}
                              />
                              {`${statInfo.name}: ${statInfo.formatter(
                                currentStat
                              )}`}
                              {shouldShowTotal && (
                                <TotalValue>
                                  ({statInfo.formatter(totalStat)})
                                </TotalValue>
                              )}
                            </TooltipValues>
                          );
                        })}
                      {Object.entries(RELATIVE_FIGHT_STATS)
                        .filter(([key, value]) => {
                          const statInfo = value as RelativeFightStatInfo;
                          return statInfo.baseStat === baseStatSlug;
                        })
                        .map(([key, value]) => {
                          const statSlug = key as keyof RelativeFightStats;
                          const statInfo = value as RelativeFightStatInfo;
                          const currentStat = statInfo.getValue({
                            attackerStats: characterStats.current.complete,
                            defenderStats:
                              opponent.derivedStats.current.complete,
                          });
                          const totalStat = statInfo.getValue({
                            attackerStats: characterStats.total.complete,
                            defenderStats:
                              opponent.derivedStats.current.complete,
                          });
                          const shouldShowTotal = currentStat !== totalStat;

                          return (
                            <TooltipValues
                              key={statSlug}
                              color={statInfo.color}
                            >
                              <ValuesIcon
                                image={statInfo.image}
                                color={statInfo.color}
                              />
                              {`${statInfo.name}: 
                            ${statInfo.formatter(currentStat)}`}
                              {shouldShowTotal && (
                                <TotalValue>
                                  ({statInfo.formatter(totalStat)})
                                </TotalValue>
                              )}
                            </TooltipValues>
                          );
                        })}
                    </TooltipInfo>
                  </>
                }
              >
                <WeakenBar
                  number={currentBaseStats[baseStatSlug]}
                  weakenNumber={weakenedBaseStats[baseStatSlug]}
                  maxNumber={maxValue}
                  color={baseStat.color}
                  isReversed={true}
                />
              </Tooltip>
            </BarWrapper>
            <Tooltip
              content={
                <>
                  <TooltipName>Attribute: {baseStat.name}</TooltipName>
                  <TooltipDesc>{baseStat.description}</TooltipDesc>
                  <TooltipDesc>{`A ship's ${baseStat.name} can be weakened in battle by targeting its ${baseStat.part}.`}</TooltipDesc>
                </>
              }
            >
              <StatIcon image={baseStat.image} color={baseStat.color} />
            </Tooltip>
            <BarWrapper>
              <Tooltip
                content={
                  <>
                    <TooltipName>{baseStat.name} Stats</TooltipName>
                    <TooltipInfo>
                      {Object.entries(DERIVED_STATS)
                        .filter(([key, value]) => {
                          const statInfo = value as DerivedStatInfo;
                          return statInfo.baseStat === baseStatSlug;
                        })
                        .map(([key, value]) => {
                          const statSlug = key as keyof CharacterDerivedStats;
                          const statInfo = value as DerivedStatInfo;
                          const currentStat =
                            opponent.derivedStats.current.complete[statSlug];
                          const totalStat =
                            opponent.derivedStats.total.complete[statSlug];
                          const shouldShowTotal = currentStat !== totalStat;

                          return (
                            <TooltipValues
                              key={statSlug}
                              color={statInfo.color}
                            >
                              <ValuesIcon
                                image={statInfo.image}
                                color={statInfo.color}
                              />
                              {`${statInfo.name}: ${statInfo.formatter(
                                currentStat
                              )}`}
                              {shouldShowTotal && (
                                <TotalValue>
                                  ({statInfo.formatter(totalStat)})
                                </TotalValue>
                              )}
                            </TooltipValues>
                          );
                        })}
                      {Object.entries(RELATIVE_FIGHT_STATS)
                        .filter(([key, value]) => {
                          const statInfo = value as RelativeFightStatInfo;
                          return statInfo.baseStat === baseStatSlug;
                        })
                        .map(([key, value]) => {
                          const statSlug = key as keyof RelativeFightStats;
                          const statInfo = value as RelativeFightStatInfo;
                          const currentStat = statInfo.getValue({
                            attackerStats:
                              opponent.derivedStats.current.complete,
                            defenderStats: characterStats.current.complete,
                          });
                          const totalStat = statInfo.getValue({
                            attackerStats: opponent.derivedStats.total.complete,
                            defenderStats: characterStats.current.complete,
                          });
                          const shouldShowTotal = currentStat !== totalStat;

                          return (
                            <TooltipValues
                              key={statSlug}
                              color={statInfo.color}
                            >
                              <ValuesIcon
                                image={statInfo.image}
                                color={statInfo.color}
                              />
                              {`${statInfo.name}: 
                            ${statInfo.formatter(currentStat)}`}
                              {shouldShowTotal && (
                                <TotalValue>
                                  ({statInfo.formatter(totalStat)})
                                </TotalValue>
                              )}
                            </TooltipValues>
                          );
                        })}
                    </TooltipInfo>
                  </>
                }
              >
                <WeakenBar
                  number={opponent.currentBaseStats[baseStatSlug]}
                  weakenNumber={opponent.weakenedBaseStats[baseStatSlug]}
                  maxNumber={maxValue}
                  color={baseStat.color}
                  isReversed={false}
                />
              </Tooltip>
            </BarWrapper>
          </StatWrapper>
        );
      })}
    </FightStatsWrapper>
  );
}
