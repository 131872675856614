import { useState } from "react";
import styled from "styled-components";

import {
  getFilteredUpgrades,
  getGroupedSupplies,
  getGroupedUpgrades,
} from "libs/item";
import { CharacterDerivedStats, ItemsGroupModel } from "types";
import {
  CategoryGroup,
  CategoryName,
  CategorySeparator,
  screenItemMixin,
} from "./DataScreen";
import InventoryItem from "./InventoryItem";
import ShopItem from "./ShopItem";
import { UpgradeFilter } from "./UpgradeFilter";

interface CommonProps {
  items: string[];
  isUpgrades: boolean;
  onClick: any;
}

interface ItemsListState {
  filter: keyof CharacterDerivedStats | null;
}

type ConditionalProps =
  | {
      isInventory: true;
      canAction?: boolean;
      isBuy?: never;
    }
  | {
      isInventory: false;
      canAction?: never;
      isBuy?: boolean;
    };

type ItemsListProps = CommonProps & ConditionalProps;

export const ItemsListWrapper = styled.div``;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const EmptyList = styled.div`
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  ${screenItemMixin}
`;

export default function ItemsList({
  items,
  isUpgrades,
  isInventory,
  canAction = false,
  isBuy = false,
  onClick,
}: ItemsListProps) {
  const [itemsListState, setItemsListState] = useState<ItemsListState>({
    filter: null,
  });

  let isEmptyList = false;
  let groupedItems = {};

  if (isUpgrades) {
    const filteredUpgrades = getFilteredUpgrades(items, itemsListState.filter);
    isEmptyList = filteredUpgrades.length === 0;
    groupedItems = getGroupedUpgrades(filteredUpgrades);
  } else {
    isEmptyList = items.length === 0;
    groupedItems = getGroupedSupplies(items);
  }

  const handleFilterChange = (filter: keyof CharacterDerivedStats | null) => {
    setItemsListState({ filter });
  };

  return (
    <ItemsListWrapper>
      {isUpgrades && (
        <UpgradeFilter
          selectedFilter={itemsListState.filter}
          onFilterChange={handleFilterChange}
        />
      )}
      <ListWrapper>
        {Object.entries(groupedItems)
          .filter(([key, value]) => {
            const groupData = value as ItemsGroupModel;
            return !!groupData.items.length;
          })
          .map(([key, value]) => {
            const groupData = value as ItemsGroupModel;
            return (
              <CategoryGroup key={key}>
                <CategoryName>
                  {groupData.name} <CategorySeparator />
                </CategoryName>
                <Items>
                  {groupData.items.map((itemSlug) => {
                    const ItemComponent = isInventory ? (
                      <InventoryItem
                        key={itemSlug}
                        slug={itemSlug}
                        canAction={canAction}
                        onClick={onClick}
                      />
                    ) : (
                      <ShopItem
                        key={itemSlug}
                        slug={itemSlug}
                        isBuy={isBuy}
                        onClick={onClick}
                      />
                    );
                    return ItemComponent;
                  })}
                </Items>
              </CategoryGroup>
            );
          })}
        {isEmptyList && <EmptyList>No Items Found</EmptyList>}
      </ListWrapper>
    </ItemsListWrapper>
  );
}
