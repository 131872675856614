import styled, { css } from "styled-components";

import { CharacterDerivedStats } from "types";
import { DERIVED_STATS } from "data/derivedStats";
import { useState } from "react";
import { getDerivedStatData } from "libs/stats";
import { highlightMixin } from "./DataScreen";
interface UpgradeFilterProps {
  selectedFilter: keyof CharacterDerivedStats | null;
  onFilterChange: (filter: keyof CharacterDerivedStats | null) => void;
}

interface FilterState {
  isOpen: boolean;
}

export const FilterWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  opacity: 0.95;
  z-index: 5;
`;

export const FilterControls = styled.div`
  width: 100%;
`;

export const FilterMain = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #2da5b2;
  ${highlightMixin}
`;

export const FilterSelect = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
`;

export const FilterText = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

export const SelectedFilter = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FilterClear = styled.div`
  margin-left: auto;
`;

export const openMixin = css`
  display: block;
`;

export const FilterList = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 15px);
  padding: 10px 0 10px;
  background-color: rgb(19, 39, 39);
  transition: height 200ms ease-in;
  width: calc(100% - 2px);
  border: 1px #43fffa60 solid;
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: none;

  ${(props) => props.$isOpen && openMixin}
`;

export const FilterItem = styled.div<{ color: string; isSelected: boolean }>`
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.color};
  display: flex;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
  background-color: ${(props) =>
    props.isSelected ? "#ffffff20" : "transparent"};
  padding: 2px 12px;

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? "#ffffff20" : "#ffffff10"};
  }
`;

export const FilterIcon = styled.img<{ image: string; color: string }>`
  width: 12px;
  height: 12px;
  mask: url(${(props) => props.image}) no-repeat center;
  background-color: ${(props) => props.color};
`;

export const UpgradeFilter = ({
  selectedFilter,
  onFilterChange,
}: UpgradeFilterProps) => {
  const [filterState, setFilterState] = useState<FilterState>({
    isOpen: false,
  });

  const handleFilterToggle = () => {
    setFilterState({ isOpen: !filterState.isOpen });
  };

  const handleFilterChange = (filter: keyof CharacterDerivedStats | null) => {
    onFilterChange(filter);
    setFilterState({ isOpen: false });
  };

  const handleFilterClear = () => {
    onFilterChange(null);
    setFilterState({ isOpen: false });
  };

  const selectedFilterInfo = selectedFilter
    ? getDerivedStatData(selectedFilter)
    : null;

  return (
    <FilterWrapper>
      <FilterControls>
        <FilterMain>
          <FilterSelect onClick={() => handleFilterToggle()}>
            {selectedFilterInfo ? (
              <>
                <FilterText>Filtering by stat:</FilterText>{" "}
                <SelectedFilter color={selectedFilterInfo.color}>
                  <FilterIcon
                    image={selectedFilterInfo.image}
                    color={selectedFilterInfo.color}
                  />
                  {selectedFilterInfo.name}
                </SelectedFilter>
              </>
            ) : (
              <FilterText>Filter by stat</FilterText>
            )}
          </FilterSelect>
          {selectedFilter && (
            <FilterClear onClick={handleFilterClear}>✕ Clear</FilterClear>
          )}
        </FilterMain>
        <FilterList $isOpen={filterState.isOpen}>
          {Object.entries(DERIVED_STATS).map(([slug, statInfo]) => {
            const filterKey = slug as keyof CharacterDerivedStats;
            return (
              <FilterItem
                key={filterKey}
                onClick={() => {
                  if (selectedFilter === filterKey) {
                    handleFilterChange(null);
                  } else {
                    handleFilterChange(filterKey);
                  }
                }}
                color={statInfo.color}
                isSelected={selectedFilter === filterKey}
              >
                <FilterIcon image={statInfo.image} color={statInfo.color} />
                {statInfo.name}
              </FilterItem>
            );
          })}
        </FilterList>
      </FilterControls>
    </FilterWrapper>
  );
};
