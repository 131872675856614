import styled from "styled-components";
import Button from "./Button";

export const OverlayBox = styled.div`
  width: 560px;
  padding: 30px;
  background-color: #0a1616;
  color: white;
  opacity: 0.95;
  display: flex;
  flex-direction: column;
  border: 1px #43fffa solid;
  border-top-width: 6px;
  pointer-events: auto;

  max-height: calc(98vh - 60px - 7px);
  overflow-y: auto;
`;

export const OverlayTitle = styled.div`
  text-transform: uppercase;
  color: #43fffa;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const DialogWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`;

export const DialogName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`;

export const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
`;

export const OverlaySection = styled.div``;

export const OverlayList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OverlayHighlight = styled.div`
  text-transform: uppercase;
  color: #e3cb44;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const OverlaySubtitle = styled.div`
  text-transform: uppercase;
  color: #2da5b2;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const OverlayInput = styled.input`
  background-color: #ffffff20;
  border: 1px #ffffff40 solid;
  padding: 10px;
  font-size: 16px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  &::placeholder {
    color: white;
    opacity: 0.75;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemName = styled.div``;

export const ItemInfo = styled.div``;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ItemImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 4px;
`;

export const OverlayStatement = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

export const OverlayActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FightOverlayActions = styled(OverlayActions)`
  margin-top: 25px;
`;

export const OverlayButton = styled(Button)`
  &:only-child {
    margin: 0 auto;
  }
`;
