import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Tooltip, { TooltipDesc, TooltipName } from "components/base/Tooltip";
import { setAuthOverlayScreen, showAuthOverlay } from "redux/actions";
import { getGameState } from "redux/selectors";

const AccountWarningWrapper = styled.div`
  opacity: 0.8;
`;

const WarningIcon = styled.div`
  height: 30px;
  width: 30px;
  border: 3px white solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 50%;
  color: white;
  font-weight: 600;
`;

export default function AccountWarning() {
  const dispatch = useDispatch();

  const startAuthFlow = () => {
    dispatch(setAuthOverlayScreen("signup"));
    dispatch(showAuthOverlay());
  };

  const { isUserAccountLinked } = useSelector(getGameState);

  return (
    <>
      {!isUserAccountLinked && (
        <AccountWarningWrapper onClick={startAuthFlow}>
          <Tooltip
            content={
              <>
                <TooltipName>Guest Account</TooltipName>
                <TooltipDesc>{`You are currently using a guest account, so your progress is being saved locally. To play across devices, click here to link your account to an email.`}</TooltipDesc>
              </>
            }
          >
            <WarningIcon>!</WarningIcon>
          </Tooltip>
        </AccountWarningWrapper>
      )}
    </>
  );
}
