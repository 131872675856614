import parse from "html-react-parser";
import styled from "styled-components";

import { ROADMAP } from "data/changelog";

const RoadmapWrapper = styled.div`
  background-color: #43fffa08;
  border-radius: 5px;
`;

const RoadmapContent = styled.ul`
  padding: 10px;
  padding-inline-start: 25px;
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-type: disc;
  list-style-position: outside;
`;

const RoadmapItem = styled.li`
  margin-bottom: 8px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export default function Roadmap() {
  return (
    <RoadmapWrapper>
      <RoadmapContent>
        {ROADMAP.map((item: string) => {
          return <RoadmapItem>{parse(item)}</RoadmapItem>;
        })}
      </RoadmapContent>
    </RoadmapWrapper>
  );
}
