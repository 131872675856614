import { useDispatch, useSelector } from "react-redux";
import {
  hideAuthOverlay,
  linkGoogleAccount,
  resetPassword,
  setAuthOverlayScreen,
  signInEmailAccount,
  signInGoogleAccount,
  signUpEmailAccount,
} from "redux/actions";
import styled from "styled-components";

import reaserAvatarImage from "assets/npc-reaser-avatar.svg";
import Dialogs from "components/base/Dialogs";
import {
  DialogName,
  DialogWrapper,
  OverlayActions,
  OverlayBox,
  OverlayContent,
  OverlayInput,
  OverlaySection,
  OverlaySubtitle,
  OverlayTitle,
  OverlayButton,
} from "components/base/Overlay";
import { useRef } from "react";
import { getGameState } from "redux/selectors";
import { useImmer } from "use-immer";

interface AuthOverlayState {
  email: string;
  password: string;
}

const AuthWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const AuthBackground = styled.div`
  background-color: #00000085;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const AuthOverlayBox = styled(OverlayBox)`
  width: 30%;
  min-width: 400px;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 300px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchLink = styled.div`
  align-self: center;
  font-size: 14px;
  cursor: pointer;
`;

const DIALOGS = {
  login:
    "So I definitely remember who you are, but I need you to tell me again. ...For me to, uh, access the records cortex correctly.",
  signup:
    "Save your progress into the records cortex by linking your information. Once that's done, I definitely won't forget who you are.",
  resetPassword: "Ha! Who's the forgetful one now? ...Okay, still me.",
};

export default function AuthOverlay() {
  const dispatch = useDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const { authOverlayScreen } = useSelector(getGameState);

  const [state, setState] = useImmer<AuthOverlayState>({
    email: "",
    password: "",
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft: AuthOverlayState) => {
      draft.email = event.target.value;
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((draft: AuthOverlayState) => {
      draft.password = event.target.value;
    });
  };

  const handleEmailSignIn = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      signInEmailAccount({ email: state.email, password: state.password })
    );
  };

  const handleEmailSignUp = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(
      signUpEmailAccount({ email: state.email, password: state.password })
    );
  };

  const handleResetPassword = (
    event: React.SyntheticEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    dispatch(resetPassword({ email: state.email }));
  };

  return (
    <AuthWrapper>
      <AuthBackground onClick={() => dispatch(hideAuthOverlay())} />
      <AuthOverlayBox>
        {authOverlayScreen === "login" && (
          <>
            <OverlayTitle>Access Account</OverlayTitle>
            <DialogWrapper>
              <DialogName>Pilot Commander Reaser</DialogName>
              <Dialogs
                avatar={reaserAvatarImage}
                currentStep={1}
                currentDialog={DIALOGS.login}
                hasAction={false}
              />
            </DialogWrapper>
            <OverlayContent>
              <OverlaySection>
                <OverlayActions>
                  <OverlayButton
                    $style="important"
                    $size="medium"
                    onClick={() => dispatch(signInGoogleAccount())}
                  >
                    Log In with Google
                  </OverlayButton>
                </OverlayActions>
              </OverlaySection>
              <OverlaySection>
                <OverlaySubtitle>Email Log In</OverlaySubtitle>
                <FormWrapper onSubmit={handleEmailSignIn}>
                  <InputWrapper>
                    <OverlayInput
                      ref={emailInputRef}
                      type="email"
                      placeholder="Email Address"
                      value={state.email}
                      onChange={handleEmailChange}
                      required
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <OverlayInput
                      ref={passwordInputRef}
                      type="password"
                      placeholder="Password"
                      value={state.password}
                      onChange={handlePasswordChange}
                      minLength={6}
                      maxLength={30}
                      required
                    />
                  </InputWrapper>
                  <OverlayActions>
                    <OverlayButton type="submit" $style="normal" $size="medium">
                      Log In
                    </OverlayButton>
                  </OverlayActions>
                  <SwitchLink
                    onClick={() =>
                      dispatch(setAuthOverlayScreen("resetPassword"))
                    }
                  >
                    Reset Password
                  </SwitchLink>
                </FormWrapper>
              </OverlaySection>
            </OverlayContent>
          </>
        )}
        {authOverlayScreen === "signup" && (
          <>
            <OverlayTitle>Link to Account</OverlayTitle>
            <DialogWrapper>
              <DialogName>Pilot Commander Reaser</DialogName>
              <Dialogs
                avatar={reaserAvatarImage}
                currentStep={1}
                currentDialog={DIALOGS.signup}
                hasAction={false}
              />
            </DialogWrapper>
            <OverlayContent>
              <OverlaySection>
                <OverlayActions>
                  <OverlayButton
                    $style="important"
                    $size="medium"
                    onClick={() => dispatch(linkGoogleAccount())}
                  >
                    Link to Google
                  </OverlayButton>
                </OverlayActions>
              </OverlaySection>
              <OverlaySection>
                <OverlaySubtitle>Email Sign Up</OverlaySubtitle>
                <FormWrapper onSubmit={handleEmailSignUp}>
                  <InputWrapper>
                    <OverlayInput
                      ref={emailInputRef}
                      type="email"
                      placeholder="Email Address"
                      value={state.email}
                      onChange={handleEmailChange}
                      required
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <OverlayInput
                      ref={passwordInputRef}
                      type="password"
                      placeholder="Password"
                      value={state.password}
                      onChange={handlePasswordChange}
                      minLength={6}
                      maxLength={30}
                      required
                    />
                  </InputWrapper>
                  <OverlayActions>
                    <OverlayButton type="submit" $size="medium" $style="normal">
                      Sign Up
                    </OverlayButton>
                  </OverlayActions>
                </FormWrapper>
              </OverlaySection>
            </OverlayContent>
          </>
        )}
        {authOverlayScreen === "resetPassword" && (
          <>
            <OverlayTitle>Reset Password</OverlayTitle>
            <DialogWrapper>
              <DialogName>Pilot Commander Reaser</DialogName>
              <Dialogs
                avatar={reaserAvatarImage}
                currentStep={1}
                currentDialog={DIALOGS.resetPassword}
                hasAction={false}
              />
            </DialogWrapper>
            <OverlayContent>
              <OverlaySection>
                <FormWrapper onSubmit={handleResetPassword}>
                  <InputWrapper>
                    <OverlayInput
                      ref={emailInputRef}
                      type="email"
                      placeholder="Email Address"
                      value={state.email}
                      onChange={handleEmailChange}
                      required
                    />
                  </InputWrapper>
                  <OverlayActions>
                    <OverlayButton type="submit" $style="normal" $size="medium">
                      Reset
                    </OverlayButton>
                  </OverlayActions>
                  <SwitchLink
                    onClick={() => dispatch(setAuthOverlayScreen("login"))}
                  >
                    Back to Log In
                  </SwitchLink>
                </FormWrapper>
              </OverlaySection>
            </OverlayContent>
          </>
        )}
      </AuthOverlayBox>
    </AuthWrapper>
  );
}
